<template>
    <div class="user-selector">
        <div class="user-selector__row user-selector__row--header">
            <div class="user-selector__column" v-if="title">
                <h2 class="user-selector__column-title">
                    {{ title }}
                </h2>
            </div>

            <div class="user-selector__column user-selector__column--select">
                <VSelect
                    ref="select"
                    class="v-select--primary v-select--visible v-select--no-option-padding"
                    label="userId"
                    :options="options"
                    :filterable="false"
                    :placeholder="t('add-user')"
                    :disabled="disabled"
                    @open="fetchUsersAsync"
                    @search="fetchOptions"
                    @option:selected="onSelected"
                    v-model="lastSelected"
                >
                    <template v-slot:no-options>
                        <span>{{ t('no-options') }}</span>
                    </template>

                    <template v-slot:option="option">
                        <div
                            class="user-selector__select-option"
                            :class="{
                                'user-selector__select-option--selected': contains(option),
                            }"
                        >
                            <VUserCard tiny :user="option"></VUserCard>
                        </div>
                    </template>
                </VSelect>
            </div>
        </div>

        <div class="user-selector__row user-selector__row--content">
            <div
                class="user-selector__column"
                :class="{
                    'user-selector__column--fluid': fluid,
                    'user-selector__column--center': users.length === 0,
                }"
            >
                <ul class="user-selector__list" v-if="users.length > 0">
                    <template v-for="(user, index) in users" :key="index">
                        <li class="user-selector__item">
                            <VUserCard :user="user"></VUserCard>

                            <VButton
                                class="button--rounded button--transparent user-selector__button user-selector__button--deselect"
                                @click="onDeselected(user!)"
                                v-if="!disabled && deletable"
                            >
                                <TimesSvg></TimesSvg>
                            </VButton>
                        </li>
                    </template>
                </ul>

                <span class="user-selector__text-hint" v-if="users.length === 0">{{ t('users-not-selected') }}</span>
            </div>
        </div>

        <div
            class="user-selector__row user-selector__row--actions user-selector__row--right"
            :class="{
                'user-selector__row--margin': actionsMargin,
            }"
        >
            <slot name="actions">
                <VButton
                    class="button--primary button--negative button--vcenter"
                    @click="$emit('cancel')"
                    v-if="cancelButtonTitle"
                >
                    {{ cancelButtonTitle }}
                    <TimesSvg></TimesSvg
                ></VButton>

                <VButton
                    class="button--primary button--green button--vcenter"
                    @click="$emit('submit', users)"
                    v-if="submitButtonTitle"
                >
                    {{ submitButtonTitle }}
                    <ArrowLeftSvg class="rotate-180"></ArrowLeftSvg
                ></VButton>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import TimesSvg from '@/assets/times.svg';
import ArrowLeftSvg from '@/assets/arrow-left.svg';

// Components
import VButton from './VButton.vue';
import VSelect from './VSelect.vue';
import VUserCard from './VUserCard.vue';

// Other
import IUser from '@/core/Models/IUser';
import UserService from '@/core/Services/UserService';
import { debounce } from 'debounce';
import { computed, markRaw, PropType, ref, defineEmits, defineProps, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['update:modelValue', 'edit-request', 'removed', 'added', 'cancel', 'submit']);
const props = defineProps({
    modelValue: { type: Array as PropType<IUser[]>, default: () => [] },
    title: { type: String, default: '' },
    fluid: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    deletable: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    actionsMargin: { type: Boolean, default: false },
    cancelButtonTitle: { type: String, default: '' },
    submitButtonTitle: { type: String, default: '' },
});

let initialOptions: IUser[] | null = null;

const root = ref(null as HTMLDivElement | null);
const options = ref([] as IUser[]);
const lastSelected = ref(null as IUser | null);

const users = computed({
    get: (): IUser[] => props.modelValue,
    set: (value: IUser[]) => emit('update:modelValue', value ?? []),
});
const fetchOptions = debounce(async (search: string, loading: (isLoading: boolean) => void) => {
    loading(true);
    try {
        await fetchUsersAsync(search);
    } finally {
        loading(false);
    }
}, 500);

onMounted(() => {
    if (props.autofocus) {
        (root.value?.querySelector('.vs__search') as HTMLInputElement | null)?.focus();
    }
});

function contains(user: IUser): boolean {
    return indexOf(user) !== -1;
}

function onSelected(user: IUser, force = false) {
    lastSelected.value = null;

    if (!force && !props.editable) {
        emit('edit-request', { callback: () => onSelected(user, true) });
        return;
    }

    const index = indexOf(user);
    if (index !== -1) {
        return onDeselected(user);
    }

    if (!props.multiple) {
        const remove = users.value;

        for (const user of remove) {
            const index = indexOf(user);

            if (index !== -1) {
                users.value.splice(index, 1);
            }

            emit('removed', user);
        }
    }

    users.value.push(user);
    emit('added', user);
    emit('update:modelValue', users.value ?? []);
}

function onDeselected(user: IUser, force = false) {
    if (!force && !props.editable) {
        emit('edit-request', { callback: () => onDeselected(user, true) });
        return;
    }

    const index = indexOf(user);

    if (index !== -1) {
        users.value.splice(index, 1);
        emit('removed', user);
        emit('update:modelValue', users.value ?? []);
    }
}

async function fetchUsersAsync(search = '') {
    if (!search && initialOptions) {
        options.value = initialOptions;
        return;
    }

    const users = await UserService.query({
        page: 1,
        perPage: 10,
        search,
    });

    options.value = users.map((user) => markRaw(user));

    if (!initialOptions) {
        initialOptions = options.value;
    }
}

function indexOf(user: IUser): number {
    return users.value.findIndex((item) => item.id === user.id);
}
</script>

<style lang="scss">
.user-selector {
    display: flex;
    position: relative;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;

    &__row {
        display: flex;

        &--right {
            justify-content: flex-end;
        }

        &--actions {
            flex-wrap: wrap;
            gap: 0.75rem;
        }

        &--margin {
            margin: 1.5rem 0;
        }

        &--content {
            height: 100%;
            min-height: 0;
        }

        &--header {
            flex-wrap: wrap;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                flex-wrap: nowrap;
            }
        }
    }

    &__list {
        margin: 1.5rem 0 0 0;
        max-width: 100%;
    }

    &__item {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 0.75rem;
    }

    &__column {
        display: flex;
        flex: 1 1;
        max-width: 100%;

        &--center {
            align-items: center;
            justify-content: center;
        }

        &--select {
            min-width: 50%;
        }
    }

    &__column-title {
        margin: 0;

        @include h2(false);
        line-height: 2.5rem;
    }

    &__button {
        line-height: 1.5rem;

        &--deselect {
            padding: 0.25rem;
            color: var(--text-black-tertiary);
        }

        & svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &__select-option {
        padding: 0.25rem 0;

        &--selected::before {
            content: ' ';
            position: absolute;
            display: inline-block;
            top: 0;
            right: 0;
            width: 1rem;
            height: 1rem;
            margin: 0.5rem 0.5rem 0.5rem 0;

            background: url(~@/assets/select-check.svg?url) center center no-repeat;
        }

        &:hover .user-card {
            color: var(--brand-green);
        }
    }

    &__text-hint {
        @include h7();
        color: var(--text-black-secondary);
    }
}
</style>
