<template>
    <Teleport to="body">
        <div class="v-modal-overlay prevent-close" @keydown.esc="close" @click="close" v-if="visible">
            <div ref="modal" class="v-modal" tabindex="-1" @click.stop v-bind="$attrs">
                <VButton
                    ref="close"
                    class="button--transparent button--rounded v-modal__button--close"
                    @click="close"
                    v-focus
                >
                    <TimesSvg></TimesSvg>
                </VButton>

                <slot></slot>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts">
// Svg
import TimesSvg from '@/assets/times.svg';

// Components
import VButton from './VButton.vue';

// Other
import { focusOnFirstTarget } from '@/utils/dom-utils';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        TimesSvg,

        VButton,
    },

    inheritAttrs: false,

    props: {
        visible: { type: Boolean, required: true },
    },

    methods: {
        close(): void {
            this.$emit('update:visible', false);
        },

        applyModalStyles() {
            if (this.visible && !document.body.classList.contains('v-modal-opened')) {
                (this.$refs.modal as HTMLDivElement | null)?.focus();
                document.body.classList.add('v-modal-opened');
            } else {
                document.body.classList.remove('v-modal-opened');
            }
        },

        focusOnModal() {
            setTimeout(() => {
                if (this.$refs.modal as HTMLDivElement | null) {
                    focusOnFirstTarget(this.$refs.modal as HTMLDivElement);
                }
            });
        },
    },

    watch: {
        visible() {
            this.applyModalStyles();
            if (this.visible) {
                this.focusOnModal();
            }
        },
    },

    mounted() {
        this.applyModalStyles();
    },
});
</script>

<style lang="scss">
.v-modal {
    position: relative;
    flex-shrink: 0;
    min-width: 20rem;
    min-height: 12rem;
    padding: 2.125rem 2.75rem;
    border-radius: 1.5rem;
    background: var(--background-color);
    --background-color: var(--background-primary);
    outline: none;

    @media (min-width: #{$breakpoint-tablet + 1}) {
        padding: 3.125rem 3.75rem;
    }

    &__title {
        margin: 0;

        @include h2();
        text-align: center;
        line-height: 2rem;
        color: var(--text-black-primary);
    }

    &__content {
        margin: 0;

        @include h7();
        line-height: 1.3125rem;
        color: var(--text-black-primary);
    }

    &__hint {
        @include caption-tertiary(false);
        color: var(--text-black-tertiary);
    }

    &__row {
        &--header {
            margin: 0 0 0.75rem 0;
        }

        &--content {
            margin: 0 0 0.5rem 0;
        }

        &--actions {
            display: flex;
            gap: 1rem;
            margin: 1.5rem 0 0 0;
        }

        &--vertical {
            display: flex;
            flex-direction: column;
        }
    }

    &__action {
        flex: 1 1 0;
        @include h4();
        text-wrap: nowrap;
        text-align: center;
        line-height: 1.625rem;
    }

    &__button {
        &--close {
            position: absolute;
            padding: 0.5rem;
            top: 0.5rem;
            right: 1rem;

            color: var(--text-black-primary);
        }
    }

    &-opened {
        position: fixed;
        right: 0;
        left: 0;
        overflow: hidden;
    }
}

.v-modal-overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem 0;
    background: var(--background-color);
    --background-color: var(--background-overlay, rgba(0, 0, 0, 0.3));
    z-index: 99999;
    opacity: 1;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
</style>
