const general = {
    'error': 'Произошла ошибка',
    'unknown': 'неизвестно',
    'unknown-error':
        'Произошла неизвестная ошибка, попробуйте перезагрузить страницу или обратиться в службу технической поддержки!',
    'create-task': 'Создать задачу',
    'create-goal': 'Создать цель',
    'my-tasks': 'Мои задачи',
    'my-goals': 'Мои цели',
    'approvements': 'Утверждения',
    'notifications': 'Уведомления',
    'all-projects': 'Все разделы',
    'sign-out': 'Выйти',
    'untitled': 'Без названия',
    'status': 'Статус',
    'goal': 'Целей | Цель | Цели | Целей',
    'goals': 'Цели',
    'task': 'Задач | Задача | Задачи | Задач',
    'tasks': 'Задачи',
    'subtask': 'Подзадач | Подзадача | Подзадачи | Подзадач',
    'deadline': 'Срок выполнения',
    'column': 'Колонка',
    'author': 'Автор',
    'assignee': 'Ответственный',
    'assignees': 'Ответственные',
    'contributor': 'Соисполнитель',
    'contributors': 'Соисполнители',
    'collaborator': 'Участник',
    'collaborators': 'Участники',
    'send-for-approvement': 'Отправить на согласование',
    'resend-for-approvement': 'Изменить состав согласующих',
    'default-placeholder': 'Не добавлен | Не добавлено | Не добавлены | Не добавлена',
    'dark-theme': 'Темная тема',
    'light-theme': 'Светлая тема',
    'all': 'Все',
    'read': 'Прочитанные',
    'unread': 'Непрочитанные',
    'users-control': 'Управление',
    'description': 'Описание',
    'attachments': 'Файлы',
    'change-account': 'Сменить аккаунт',
    'attached-tasks': 'Добавленные задачи',
    'file-size-limit-hint': 'до {limit}',
    'upload-new-file': 'Загрузить новый файл',
    'approve': 'Согласовать',
    'disapprove': 'Отказать',
    'board-link-text': 'на доске',
    'approval-list-title': 'Список согласующих',
    'your-comment': 'Ваш комментарий...',
    'delete-file': 'Удалить файл',
    'archive': 'Добавить в архив',
    'feed-story-title': 'События и комментарии',
    'actions': 'События',
    'comments': 'Комментарии',
    'user-draft-column-title': 'Черновики',
    'user-approvements-column-title': 'Согласование',
    'user-draft-approvements-column-title': 'Черновики, Согласование',
    'no-deadline': 'Без срока',
    'goal-number': 'Цель №{number}',
    'task-number': 'Задача №{number}',
    'edit': 'Редактировать',
    'edited': 'отр.',
    'go-back': 'Вернуться назад',
    'goto-task': 'К задаче',
    'goto-goal': 'К цели',
    'goto-service-desk': 'Тех. Поддержка',
    'add-user': 'Добавить пользователя',
    'add-subtask': 'Добавить подзадачу',
    'select-user': 'Выберите пользователя',
    'no-options': 'Извините, ничего не найдено',
    'complete': 'Выполнить',
    'finish': 'Принять',
    'rollback-changes': 'Отменить изменения',
    'return-to-in-progress': 'Отклонить',
    'enter-title': 'Введите название',
    'add-new-column': 'Добавить колонку',
    'alert-title': 'Уведомление!!!',
    'confirm-title': 'Подтверждение действия!',
    'users-not-selected': 'Пользователи еще не добавлены',
    'no-data': 'Ничего не найдено',
    'no-data-0': 'Ничего не найдено',
    'no-data-1': 'Опять пусто',
    'no-data-2': '404 Not found (learn english)',
    'no-data-3': 'Тут пусто, родной...',
    'no-data-4': 'Четыре ноль четыре, пусто',
    'no-data-5': 'Не повезло, не повезло, пусто',
    'copy-link': 'Скопировать ссылку',
    'ok': 'Ок',
    'yes': 'Да',
    'open': 'Открыть',
    'open-in-new-tab': 'Открыть в новой вкладке',
    'apply': 'Применить',
    'cancel': 'Отменить',
    'reload': 'Перезагрузить',
    'added-action': 'добавил',
    'added-preposition': 'в',
    'removed-action': 'исключил',
    'removed-preposition': 'из',
    'subtasks': 'Подзадачи',
    'download': 'Скачать',
    'settings': 'Настройки',
    'remove-story': 'Удалить cообщение',
    'change-story': 'Изменить сообщение',
    'remove-notification': 'Удалить уведомление',
    'mark-all-as-read': 'Пометить все как прочитанное',
    'remove-all-notifications': 'Удалить все уведомления',
    'remove-all-read-notifications': 'Удалить все прочитанные уведомления',
    'mark-as-unread': 'Пометить как непрочитанный',
    'show-all-stories': 'Показать все',
    'show-more-stories': 'Показать еще {0} событий из {1}',
    'goal-not-found': 'Нам не удалось найти эту цель',
    'task-not-found': 'Нам не удалось найти эту задачу',
    'fulfilled-required': 'Необходимо заполнить все обязательные поля',
    'task-coming-to-deadline': 'Крайний срок {0} наступает {1}',
    'deadline-expired': 'Крайний срок {0} истек {1}',
    'deleted': 'Удалено',
    'comment': 'Комментарии',
    'approvers': 'Согласующие',
    'attached-to-task': 'привязана к задаче',
    'field-required': 'Это поле обязательные к заполнению',
    'task-archived': 'Задача находится в архиве',
    'goal-archived': 'Цель находится в архиве',
    'confirm-task-edit':
        'Для того чтобы отредактировать задачу, ее статус будет изменен на "Черновик". Затем вам потребуется повторно согласовать ее или отменить изменения.',
    'confirm-goal-edit':
        'Для того чтобы отредактировать задачу, ее статус будет изменен на "Черновик". Затем вам потребуется повторно согласовать ее или отменить изменения.',
    'confirm-rollback-changes': 'Вы уверены что хотите отменить изменения?',
    'confirm-task-or-goal-approve': 'Вы уверенны что хотите согласовать?',
    'confirm-task-or-goal-approve-hint': 'Добавить свои примечания по согласованию.',
    'confirm-task-or-goal-disapprove': 'Необходимо указать причину отказа:',
    'confirm-task-complete': 'Вы уверены что хотите выполнить эту задачу?',
    'confirm-goal-complete': 'Вы уверены что хотите выполнить эту цель?',
    'confirm-task-finish': 'Вы уверены что хотите принять эту задачу?',
    'confirm-goal-finish': 'Вы уверены что хотите принять эту цель?',
    'confirm-task-to-in-progress': 'Вы уверены что хотите вернуть эту задачу в работу?',
    'confirm-goal-to-in-progress': 'Вы уверены что хотите вернуть эту цель в работу?',
    'confirm-task-or-goal-to-in-progress-hint': 'Добавить причину возврата в работу.',
    'confirm-task-archiving': 'Вы уверены, что хотите добавить эту задачу в архив?',
    'confirm-goal-archiving': 'Вы уверены, что хотите добавить эту цель в архив?',
    'confirm-task-archiving-hint':
        'Задача не будет появляться в общем списке задач, но вы можете найти её с помощью специального фильтра.',
    'confirm-goal-archiving-hint':
        'Цель не будет появляться в общем списке целей, но вы можете найти её с помощью специального фильтра.',
    'filter': 'Фильтр',
    'filters': 'Фильтры',
    'search': 'Поиск',
    'menu': 'Меню',
    'clear': 'Очистить',
    'create': 'Cоздать',
    'show-archived': 'Показать заархивированные',
    'add-filter': 'Добавить',
    'creation-date': 'Дата создания',
    'page-not-found': 'Страница не найдена',
    'select-what-create': 'Что создаем?',
    'with-comment': 'с коментарием',
    'show-difference': 'Показать изменения',
    'hide-difference': 'Скрыть изменения',
    'statistic': 'Показатели',
    'task-view-mode-board': 'Доска',
    'task-view-mode-table': 'Таблица',
    'page-fullscreen': 'Полноразмерный режим',
    'task-view-mode': 'Режим отображения задач',
    'sort': 'Сортировка',
    'move': 'Передвинуть',
    'move-left': 'Передвинуть влево',
    'move-right': 'Передвинуть вправо',
    'order-reset': 'Сбросить сортировку',
    'order-ascending': 'Сортировка по возрастанию',
    'order-descending': 'Сортировка по убыванию',
    'hide-column': 'Скрыть колонку',
    'enable-winter-mode': 'Включить зиму',
    'disable-winter-mode': 'Выключить зиму',
    'columns': 'Колонки',
    'quarter1': '1 квартал',
    'quarter2': '2 квартал',
    'quarter3': '3 квартал',
    'quarter4': '4 квартал',
};

const application = {
    errors: {
        'server-error':
            'Произошла неизвестная ошибка, попробуйте перезагрузить страницу или обратиться в службу технической поддержки!',
        'not-enough-permissions':
            'У вас недостаточно прав, попробуйте перезагрузить страницу или обратиться в службу технической поддержки!',
        'websocket-connection-error':
            'Не удалось установить подключение к сайту. \n\n Возможные шаги к устранению проблемы: \n 1. Проверьте подключение к интернету. \n  2. Попробуйте перезагрузить страницу. \n 3. Повторите попытку позже.',
        'too-many-failed-authorization-attempts':
            'Слишком много неудачных попыток авторизации, попробуйте перезагрузить страницу или повторите попытку позже',
        'max-file-name-limit': 'Размер файла не должно превышать {0} символов',
        'max-file-size-limit': 'Название файла не должно превышать {0}',
    },
};

const statuses = {
    'draft': 'Черновик',
    'draft-in-progress': 'На редактировании',
    'approvement': 'На согласовании',
    'in-progress': 'В работе',
    'completed': 'Выполнена',
    'rejected': 'Отклонена',
    'finished': 'Завершена',
    'archived': 'В архиве',
};

const approvalStatuses = {
    waiting: 'На проверке',
    approved: 'Согласовано',
    disapproved: 'Отказано',
    skipped: 'Пропущено',
};

const serverKpiShared = {
    v1: {
        'project-year': '{0} год',
        'board-goals': 'Цели',
        'board-quarter': '{0} квартал',
        'default-approvement': 'Согласование от {0}',
        'changes-approvement': 'Согласование изменений от {0}',
    },
};

const serverKpiTasks = {
    v1: {
        'task-story': {
            'created': 'создал(а) эту задачу.',
            'title-changed': 'изменил(а) название задачи.',
            'deadline-changed': 'изменил(а) крайний срок задачи.',
            'description-changed': 'изменил(а) описание задачи.',
            'number-of-assignee-changed': 'изменил(а) состав ответственных.',
            'number-of-managers-changed': 'изменил(а) состав ответственных.',
            'number-of-contributors-changed': 'изменил(а) состав соисполнителей.',
            'approvement-process-started': 'отправил(а) задачу на согласование.',
            'approved': 'согласовал(а) задачу.',
            'disapproved': 'отказал(а) в согласовании этой задачи с комментарием: {0}',
            'in-progress': 'отправил(а) задачу в работу.',
            'rejected': 'отправил(а) задачу в отклоненные.',
            'completed': 'выполнил(а) задачу.',
            'moved': 'переместил(а) задачу.',
            'attached-to': 'прикрепил(а) задачу как подзадачу.',
            'finished': 'принял(а) задачу.',
            'to-draft': 'отправил(а) задачу на редактирование.',
            'archived': 'заархивировал(а) задачу.',
            'rollback-changes': 'отменил(а) изменения в задаче.',
            'author-changed': 'изменил(а) автора задачи.',
            'unknown': 'у нас ошибка, возможно криминал по коням.',
            'attachment': 'Файл',
        },
        'goal-story': {
            'created': 'создал(а) эту цель.',
            'title-changed': 'изменил(а) название цели.',
            'deadline-changed': 'изменил(а) крайний срок цели.',
            'description-changed': 'изменил(а) описание цели.',
            'number-of-assignee-changed': 'изменил(а) состав ответственных.',
            'number-of-managers-changed': 'изменил(а) состав ответственных.',
            'number-of-contributors-changed': 'изменил(а) состав соисполнителей.',
            'approvement-process-started': 'отправил(а) цель на согласование.',
            'approved': 'согласовал(а) цель.',
            'disapproved-template': 'отказал(а) в согласовании этой цели с комментарием: {comment}',
            'in-progress': 'отправил(а) цель в работу.',
            'rejected': 'отправил(а) цель в отклоненные.',
            'completed': 'выполнил(а) цель.',
            'moved': 'переместил(а) цель.',
            'attached-to': 'прикрепил(а) цель как подзадачу.',
            'finished': 'принял(а) цель.',
            'to-draft': 'отправил(а) цель на редактирование.',
            'archived': 'заархивировал(а) цель.',
            'rollback-changes': 'отменил(а) изменения в цели.',
            'author-changed': 'изменил(а) автора цели.',
            'unknown': 'у нас ошибка, возможно криминал по коням.',
            'attachment': 'Файл',
        },
    },
    v2: {
        'task-story': {
            'deadline-changed-1': 'установил(а) крайний срок задачи на {new}.',
            'deadline-changed-2': 'изменил(а) крайний срок задачи с {old} на {new}.',
            'number-of-assignee-changed': '{action}(а) {user} {preposition} состава ответственных.',
            'number-of-contributors-changed': '{action}(а) {user} {preposition} состав соисполнителей.',
            'author-changed': 'изменил(а) автора задачи на {user}.',
            'approved': 'согласовал(а) задачу{comment}.',
            'disapproved': 'отказал(а) в согласовании задачи{comment}.',
            'return-in-progress': 'вернул(а) задачу в работу{comment}.',
            'title-changed': 'изменил(а) название задачи. {showDiff}',
            'description-changed': 'изменил(а) описание задачи. {showDiff}',
        },
        'goal-story': {
            'deadline-changed-1': 'установил(а) крайний срок цели на {new}.',
            'deadline-changed-2': 'изменил(а) крайний срок цели с {old} на {new}.',
            'number-of-assignee-changed': '{action}(а) {user} {preposition} состава ответственных.',
            'number-of-contributors-changed': '{action}(а) {user} {preposition} состав соисполнителей.',
            'author-changed': 'изменил(а) автора цели на {user}.',
            'approved': 'согласовал(а) цель{comment}.',
            'disapproved': 'отказал(а) в согласовании цели{comment}.',
            'return-in-progress': 'вернул(а) цель в работу{comment}.',
            'title-changed': 'изменил(а) название цели. {showDiff}',
            'description-changed': 'изменил(а) описание цели. {showDiff}',
        },
    },
};

const serverKpiNotifications = {
    v1: {
        'task-awaiting-your-approval': 'Задача ожидает вашего согласования.',
        'goal-awaiting-your-approval': 'Цель ожидает вашего согласования.',
        'task-deadline-expired': 'Крайний срок задачи истек.',
        'goal-deadline-expired': 'Крайний срок цели истек.',
        'task-deadline-in-days': 'Крайний срок задачи истекает через {0} дней.',
        'goal-deadline-in-days': 'Крайний срок цели истекает через {0} дней.',
        'task-deadline-today': 'Крайний срок задачи истекает сегодня.',
        'goal-deadline-today': 'Крайний срок цели истекает сегодня.',
        'task-deadline-tomorrow': 'Крайний срок задачи истекает завтра.',
        'goal-deadline-tomorrow': 'Крайний срок цели истекает завтра.',
    },
};

export default {
    ...general,
    'kpi-tasks': serverKpiTasks,
    'kpi-shared': serverKpiShared,
    'kpi-notifications': serverKpiNotifications,
    'statuses': statuses,
    'approval-statuses': approvalStatuses,
    'application': application,
};
