<template>
    <div class="status status--indicator" :class="className">
        {{ statusTitle }}
    </div>
</template>

<script setup lang="ts">
// Other
import ITask from '@/core/Models/ITask';
import Status, { StatusTitle } from '../core/Values/Status';
import { PropType, computed, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const statusColorClassName = {
    [Status.Draft]: 'status--blurry',
    [Status.Approvement]: 'status--orange',
    [Status.InProgress]: 'status--positive',
    [Status.Completed]: 'status--orange',
    [Status.Rejected]: 'status--blurry',
    [Status.Finished]: 'status--green',
};

const { t } = useI18n();
const props = defineProps({
    task: { type: Object as PropType<ITask>, required: true },
    showTitle: { type: Boolean, default: true },
});

const statusTitle = computed((): string => {
    if (!props.showTitle) {
        return '';
    }

    if (props.task.archivedAt) {
        return t('statuses.archived');
    }

    if (props.task.status === Status.Draft && props.task.inProgressAt) {
        return t('statuses.draft-in-progress');
    }

    const statusTitle = StatusTitle[props.task.status as keyof typeof StatusTitle] as string;

    return t(statusTitle ?? 'unknown');
});
const className = computed((): string => {
    if (props.task.archivedAt) {
        return t('status--negative');
    }

    return statusColorClassName[props.task.status as unknown as keyof typeof statusColorClassName] ?? '';
});
</script>

<style lang="scss">
:root {
    --status-color-black: var(--text-black-primary);
    --status-color-blurry: var(--text-black-tertiary);
    --status-color-orange: var(--brand-orange);
    --status-color-positive: var(--color-positive);
    --status-color-green: var(--brand-green);
    --status-color-negative: var(--color-negative);
}

.status {
    position: relative;
    display: inline-block;
    text-wrap: nowrap;
    min-height: 1.5rem;

    @include caption-secondary();
    line-height: 1.5rem;

    &--space-right {
        margin-right: 0.75rem;
    }

    &--indicator {
        padding-left: 1.5rem;

        &::before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 0.75rem;
            height: 0.75rem;
            margin: 0.375rem;
            border-radius: 50%;
        }
    }

    &--blurry {
        color: var(--status-color-blurry);

        &::before {
            background: var(--background-color);
            --background-color: var(--status-color-blurry);
        }
    }

    &--orange {
        color: var(--status-color-orange);

        &::before {
            background-color: var(--background-color);
            --background-color: var(--status-color-orange);
        }
    }

    &--positive {
        color: var(--status-color-positive);

        &::before {
            background-color: var(--background-color);
            --background-color: var(--status-color-positive);
        }
    }

    &--green {
        color: var(--status-color-green);

        &::before {
            background-color: var(--background-color);
            --background-color: var(--status-color-green);
        }
    }

    &--negative {
        color: var(--status-color-negative);

        &::before {
            background-color: var(--background-color);
            --background-color: var(--status-color-negative);
        }
    }
}
</style>
