import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "feed-story" }
const _hoisted_2 = { class: "feed-story__item" }
const _hoisted_3 = {
  key: 0,
  class: "feed-story__item feed-story__item--offset feed-story__item--center"
}
const _hoisted_4 = {
  key: 1,
  class: "feed-story__item"
}

import EditSvg from '@/assets/edit.svg';
import RemoveSvg from '@/assets/remove.svg';

// Components
import VButton from './VButton.vue';
import VFeedStoryRecord from './VFeedStoryRecord.vue';

// Other
import IStory from '@/core/Models/IStory';
import ITask from '@/core/Models/ITask';
import IFeedStoryRecord from '@/core/Values/IFeedStoryRecord';
import StoryType from '@/core/Values/StoryType';
import { DateTime } from 'luxon';
import { markRaw, PropType, ref, computed, onMounted, nextTick, watch } from 'vue';
import orderBy from 'lodash.orderby';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { fileExtension } from '@/utils/utils';
import StoryViewMode from '@/core/Values/StoryViewMode';
import { useI18n } from 'vue-i18n';
import { tryTranslate } from '@/plugins/VueI18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VFeedStory',
  props: {
    task: { type: Object as PropType<ITask>, required: true },
    stories: { type: Array as PropType<IStory[]>, default: () => [] },
    viewMode: { type: Number as PropType<StoryViewMode>, default: StoryViewMode.All },
    selectable: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    forceCollapseFirst: { type: Boolean, default: false },
},
  emits: ['remove-story', 'start-edit-story'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const emit = __emit;
const props = __props;

let showCount = ref(10);
let showMoreCount = ref(10);
let followChat = ref(true);

const scroll = ref(null as HTMLDivElement | null);
const renderStories = computed(() => orderBy(props.stories.filter(viewModeFilter), (story) => story.id));
const showMoreButton = computed(() => hiddenRecordsCount.value > 0);
const hiddenRecordsCount = computed(() => Math.max(renderStories.value.length - showCount.value - 1, 0));
const records = computed(transformToRecords);

watch(records, () => {
    if (followChat.value) {
        nextTick(scrollToEnd);
    }
});
onMounted(() => nextTick(scrollToEnd));

function transformToRecords(): IFeedStoryRecord[] {
    if (!renderStories.value.length) {
        return [];
    }

    let lastStory: IStory | null = null;

    if (!renderStories.value.length) {
        return [];
    }

    // Takes the first story and the show number of stories from the end.
    const startIndex = -Math.min(showCount.value, renderStories.value.length) + 1;
    const stories =
        startIndex === 0
            ? renderStories.value.slice(0, 1)
            : renderStories.value.slice(0, 1).concat(renderStories.value.slice(startIndex));

    const records = stories.map((story, index): IFeedStoryRecord => {
        // records collapsing.
        const { collapsed, offset, hideActor } = getSharedOptions(lastStory, story);

        const record: IFeedStoryRecord = {
            id: story.id,
            actor: {
                id: story.actor?.id ?? '',
                picture: story.actor?.picture,
                displayName: story.actor?.displayName ?? 'Deleted user',
                weakColor: story.type === StoryType.Action,
                withPicture: story.type !== StoryType.Action,
                hideActor: hideActor,
            },
            offset: offset,
            padding: story.type !== StoryType.Action,
            timestamp: story.createdAt,
            // first 2 records cannot be collapsed
            collapsed: (props.forceCollapseFirst || index > 1) && collapsed,
            actions: getActions(story),
            content: story.text,
            contentEdited: !!story.editAt,
        };

        lastStory = story;

        if (story.type === StoryType.Attachment && story.downloadUri) {
            record.attachment = {
                id: story.id,
                authorId: story.actorId,
                fileName: story.fileName,
                objectName: story.objectName,
                extension: fileExtension(story.fileName),
                downloadUri: story.downloadUri,
            };
            record.content = '';
        }

        if (story.type === StoryType.Action) {
            record.header = {
                content: record.content ?? '',
            };
            record.content = '';
        }

        return record;
    });

    return records;
}

function showMore() {
    showCount.value = showCount.value + showMoreCount.value;
}

function onScroll(event: UIEvent) {
    const scroll = event.target as HTMLDivElement;
    followChat.value = scroll.scrollHeight - scroll.scrollTop === scroll.clientHeight;
}

function scrollToEnd() {
    if (scroll.value) {
        scroll.value.scrollTop = scroll.value.scrollHeight;
    }
}

function getActions(story: IStory): IDropdownOption[] | undefined {
    const canDelete = AuthorizationProvider.authorize(story, Operations.DeleteStory);
    const canUpdate = AuthorizationProvider.authorize(story, Operations.UpdateComment);

    const actions = [];

    if (canDelete && [StoryType.Comment, StoryType.Attachment].includes(story.type)) {
        actions.push({
            icon: markRaw(RemoveSvg),
            title: t('remove-story'),
            action: () => emit('remove-story', story),
        });
    }

    if (canUpdate && [StoryType.Comment].includes(story.type)) {
        actions.push({
            icon: markRaw(EditSvg),
            title: t('change-story'),
            action: () => emit('start-edit-story', story),
        });
    }

    return !actions.length ? undefined : actions;
}

function getSharedOptions(
    prevStory: IStory | null,
    nextStory: IStory,
): {
    offset: boolean;
    collapsed: boolean;
    hideActor: boolean;
} {
    if (!prevStory) {
        return {
            offset: false,
            collapsed: false,
            hideActor: false,
        };
    }

    const nextDate = DateTime.fromISO(nextStory.createdAt, { zone: 'UTC' });
    const prevDate = DateTime.fromISO(prevStory.createdAt, { zone: 'UTC' });

    const inSameTime = nextDate.diff(prevDate, 'minutes').minutes < 15;
    const isCollapsable = isCollapsableStoryType(nextStory.type);
    const isSameType = getUnionStoryType(prevStory.type) === getUnionStoryType(nextStory.type);
    const isSameActor = prevStory.actorId === nextStory.actorId;

    return {
        offset: nextStory.type !== prevStory.type && (!isSameActor || !isSameType),
        collapsed: isSameActor && isSameType && inSameTime && isCollapsable,
        hideActor: false,
    };
}

function viewModeFilter(story: IStory) {
    switch (props.viewMode) {
        case StoryViewMode.Actions:
            return story.type === StoryType.Action;
        case StoryViewMode.Comments:
            return story.type !== StoryType.Action;
        default:
            return true;
    }
}

function isCollapsableStoryType(storyType: StoryType) {
    switch (storyType) {
        case StoryType.Comment:
            return true;
        case StoryType.Attachment:
            return true;
        case StoryType.Action:
            return false;

        default:
            return false;
    }
}

function getUnionStoryType(storyType: StoryType) {
    switch (storyType) {
        case StoryType.Comment:
            return 1;
        case StoryType.Attachment:
            return 1;
        case StoryType.Action:
            return 2;

        default:
            return -1;
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      ref_key: "scroll",
      ref: scroll,
      class: _normalizeClass(["feed-story__list", {
                'feed-story__list--scroll': __props.scrollable,
            }]),
      onScroll: onScroll
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(records.value, (record, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: record.id
        }, [
          (index === 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("li", _hoisted_2, [
                  _createVNode(VFeedStoryRecord, {
                    record: record,
                    selectable: __props.selectable
                  }, null, 8, ["record", "selectable"])
                ]),
                (showMoreButton.value)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                      _createVNode(VButton, {
                        class: "button--default button--secondary",
                        onClick: showMore
                      }, {
                        default: _withCtx(() => [
                          (hiddenRecordsCount.value < _unref(showMoreCount))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_unref(t)('show-all-stories')), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_unref(t)('show-more-stories', [_unref(showMoreCount), hiddenRecordsCount.value])), 1)
                              ], 64))
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createElementBlock("li", _hoisted_4, [
                _createVNode(VFeedStoryRecord, {
                  record: record,
                  selectable: __props.selectable
                }, null, 8, ["record", "selectable"])
              ]))
        ], 64))
      }), 128))
    ], 34)
  ]))
}
}

})