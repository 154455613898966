import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "user-card__avatar" }
const _hoisted_2 = {
  key: 0,
  class: "user-card__picture user-card__picture--empty"
}
const _hoisted_3 = {
  key: 1,
  class: "user-card__picture user-card__picture--ninja"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "user-card__picture user-card__picture--symbols"
}

import UserSvg from '@/assets/user.svg';
import VUserNinjaSvg from '@/assets/user-ninja.svg';

// Components

// Other
import IUser from '@/core/Models/IUser';
import { computed, PropType, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VUserCard',
  props: {
    user: { type: Object as PropType<IUser | null>, default: null },
    tiny: { type: Boolean, default: false },
    empty: { type: String, default: '' },
    pictureOnly: { type: Boolean, default: false },
},
  setup(__props) {

// Svg
const props = __props;

let isPictureLoaded = ref(true);

const displayName = computed((): string => {
    return props.user?.displayName ?? props.empty ?? '';
});
const userPictureSymbols = computed((): string => {
    if (!props.user) {
        return '';
    }

    let symbols = '_';

    if (props.user.displayName?.length > 0) {
        var split = props.user.displayName.split(' ');

        if (split.length > 1) {
            symbols = split[0][0] + split[1][0]; // Evgeny Kahabrov -> EK
        } else {
            symbols = props.user.displayName.substring(0, 1); // Evgeny -> E
        }
    }

    return symbols.toUpperCase();
});

function onPictureLoadError() {
    isPictureLoaded.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-card", {
            'user-card--tiny': __props.tiny,
            'user-card--empty': __props.empty,
        }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (__props.empty)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createVNode(_unref(UserSvg))
          ]))
        : (__props.user && __props.user.id === '00000000-0000-0000-0000-000000000001')
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createVNode(_unref(VUserNinjaSvg))
            ]))
          : (__props.user)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (__props.user.picture)
                  ? _withDirectives((_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "user-card__picture",
                      src: __props.user?.picture,
                      onError: onPictureLoadError
                    }, null, 40, _hoisted_4)), [
                      [_vShow, _unref(isPictureLoaded)]
                    ])
                  : _createCommentVNode("", true),
                (!__props.user.picture || !_unref(isPictureLoaded))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(userPictureSymbols.value), 1))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
    ]),
    (!__props.pictureOnly)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["user-card__display-name", { 'user-card__display-name--blurry': __props.empty }])
        }, _toDisplayString(displayName.value), 3))
      : _createCommentVNode("", true)
  ], 2))
}
}

})