<template>
    <VPage>
        <VPageHeader show-third-block :title="pageTitle">
            <template v-slot:second>
                <div class="app-page-header__column app-page-header__column--tools">
                    <VTaskFilter
                        class="prevent-close"
                        :filter-types="[
                            TaskFilterTypes.Author,
                            TaskFilterTypes.Status,
                            TaskFilterTypes.Assignee,
                            TaskFilterTypes.Contributor,
                            TaskFilterTypes.AuthorOrAssignee,
                            TaskFilterTypes.Deadline,
                            TaskFilterTypes.CreationDate,
                        ]"
                        v-model="filters"
                    ></VTaskFilter>

                    <VSearchField class="prevent-close" debounce-mode v-model="searchString"></VSearchField>
                </div>
            </template>
        </VPageHeader>

        <VPageContent class="" vertical>
            <VTable v-model:table="table" v-model:order="order">
                <VTableDataSet :title="t('tasks')" v-if="tasks.length">
                    <template v-for="(task, index) in tasks" :key="task.id">
                        <RouterLink class="prevent-close" :to="{ query: { task: task.id } }">
                            <VTableRow
                                :table="table"
                                :data="task"
                                :index="index"
                                :active="task.id.toString() == route.query.task"
                                @contextmenu="openContextMenu($event, task)"
                            ></VTableRow>
                        </RouterLink>
                    </template>
                </VTableDataSet>

                <VTableDataSet :title="t('goals')" v-if="goals.length">
                    <template v-for="(goal, index) in goals" :key="goal.id">
                        <RouterLink :to="{ name: 'goals.view', params: { goalId: goal.id } }">
                            <VTableRow
                                :table="table"
                                :data="goal"
                                :index="index"
                                :active="goal.id.toString() == route.query.task"
                                @contextmenu="openContextMenu($event, goal)"
                            ></VTableRow>
                        </RouterLink>
                    </template>
                </VTableDataSet>
            </VTable>
        </VPageContent>
    </VPage>
</template>

<script setup lang="ts">
// Svg

// Components
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTable from '@/components/VTable.vue';
import VTableRow from '@/components/VTableRow.vue';
import VTableDataSet from '@/components/VTableDataSet.vue';

// Other
import { useI18n } from 'vue-i18n';
import { computed, markRaw, ref, watch } from 'vue';
import Storages from '@/core/Storages';
import { IOrder } from '@/core/Values/IOrder';
import Settings from '@/core/Settings';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import UserMapper from '@/core/UserMapper';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import MutationBus from '@/core/Mutations/MutationBus';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import store from '@/store';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import useTaskTableViewer, { journalColumns } from '@/mixins/TaskTableViewer';
import { useRoute } from 'vue-router';
import TaskType from '@/core/Values/TaskType';

const { t } = useI18n();
const route = useRoute();
const { openContextMenu } = useTaskContextMenu();
const table = useTaskTableViewer(Settings.UI.Table + '.statistic', {
    header: true,
    columns: journalColumns,
});

let goalsAndTasks = ref([] as ITask[]);
let searchString = ref('');
let mutatorContext = null as IMutatorContext | null;
let currentUser = store.state.user;
let pageTitle = t('statistic');

const order = computed(
    Storages.Filters.computed<IOrder>(Settings.UI.Order + '.statistic', {
        orderBy: 'id',
        orderAscending: false,
    }),
);
const filters = computed(
    Storages.Filters.computed(Settings.UI.Filters + '.statistic', { value: [], formattedValue: {} }),
);
const goals = computed(() => goalsAndTasks.value.filter((task) => task.type === TaskType.Goal));
const tasks = computed(() => goalsAndTasks.value.filter((task) => task.type === TaskType.Task));

const tasksFilters = computed(
    (): Partial<QueryTaskRequest> => ({
        ...order.value,
        ...filters.value.formattedValue,
        includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators'],
        search: searchString.value ?? undefined,
    }),
);

watch(tasksFilters, fetchData);

async function fetchData(): Promise<void> {
    goalsAndTasks.value = await TaskService.queryAsync(tasksFilters.value);

    // Attaching of users and preview to tasks.
    UserMapper.mapTasksAsync(goalsAndTasks.value);
    ObjectStorageMapper.mapTasksAsync(goalsAndTasks.value);

    MutationBus.deactivate(mutatorContext);
    mutatorContext = markRaw(
        new TaskMutatorContext(goalsAndTasks.value, {
            mapUsers: true,
            mapPreview: false,
            // Fetches a task if the patch contains the changes that can affect of the filters.
            fetchTask: async (patch: Partial<ITask>) => {
                if (!TaskService.matchPatch(patch, currentUser?.id as string, tasksFilters.value)) {
                    return undefined;
                }

                const tasks = await TaskService.queryAsync({
                    ...tasksFilters.value,
                    whereId: [patch.id as number],
                    perPage: 1,
                });

                if (!tasks.length) {
                    return undefined;
                }

                return UserMapper.mapTaskAsync(tasks[0]);
            },

            // Excludes tasks if they are not matching the specified filters.
            excludeTask: (task: ITask) => !TaskService.match(task, currentUser?.id as string, tasksFilters.value),
            // Ignores tasks creation if they are not matching the specified filters.
            ignoreTaskCreating: (task: ITask) => TaskService.match(task, currentUser?.id as string, tasksFilters.value),
        }),
    );
    MutationBus.activate(mutatorContext);
}

fetchData();
</script>

<style lang="scss"></style>
