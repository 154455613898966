<template>
    <VPane
        class="splitpanes__pane--sidebar splitpanes__pane--shadow"
        :class="{
            'splitpanes__pane--fixed': $device.isMobile,
        }"
        min-size="35"
        max-size="80"
        :size="$device.isMobile ? 100 : panelWidth"
        v-if="visible"
    >
        <aside class="app-page app-page--no-shrink app-page--panel prevent-close">
            <template v-if="loading">
                <div class="home-task-panel__content home-task-panel__content--center">
                    <VLoader class="loader--mini loader--center"></VLoader>
                </div>
            </template>

            <template v-else-if="!task">
                <div class="home-task-panel__content home-task-panel__content--center">
                    <VIllustration :tip="t('task-not-found')">
                        <NoDataSvg v-if="theme === 'light'"></NoDataSvg>
                        <NoDataDarkSvg v-else-if="theme === 'dark'"></NoDataDarkSvg>
                    </VIllustration>
                </div>
            </template>

            <template v-else>
                <VTaskPanel
                    :task="task"
                    :project="project"
                    :board="board"
                    :parent="parent"
                    :subtasks="subtasks"
                    :can-create-sub-tasks="canCreateSubTasks"
                    :uploader="uploader"
                    @close="close"
                ></VTaskPanel>
            </template>
        </aside>
    </VPane>
</template>

<script lang="ts">
// Svg
import NoDataSvg from '@/assets/no-data.svg';
import NoDataDarkSvg from '@/assets/no-data-dark.svg';

// Components
import VLoader from '../components/VLoader.vue';
import VTaskPanel from '../components/VTaskPanel.vue';
import VIllustration from '../components/VIllustration.vue';
import { Pane as VPane } from 'splitpanes';

// Other
import { pushPageTitle, pullPageTitle } from '@/utils/document-utils';
import ITask from '@/core/Models/ITask';
import { hasParentWithClassName, isClickOnScrollbar } from '@/utils/dom-utils';
import TaskService from '@/core/Services/TaskService';
import UserMapper from '@/core/UserMapper';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import TaskType from '@/core/Values/TaskType';
import { Raw, markRaw, ref } from 'vue';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import { objectStorageUploader } from '@/core/Uploader/UploaderBuilder';
import { defineComponent } from 'vue';
import { $error } from '@/utils/app-utils';
import store from '@/store';
import IBoard from '@/core/Models/IBoard';
import IProject from '@/core/Models/IProject';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    components: {
        NoDataSvg,
        NoDataDarkSvg,

        VPane,
        VLoader,
        VTaskPanel,
        VIllustration,
    },

    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        return {
            t,
            route,
            router,

            loading: ref(true),

            task: ref(null as ITask | null),
            parent: ref(null as ITask | null),
            subtasks: ref(null as ITask[] | null),

            uploader: objectStorageUploader,

            mutatorContexts: ref(null as Raw<IMutatorContext[]> | null),

            removeAfterEachListener: ref(null as (() => void) | null),
        };
    },

    computed: {
        panelWidth: Storages.Settings.computedDebounce(Settings.UI.PanelWidth, 50),

        theme() {
            return Storages.Settings.get(Settings.UI.Theme);
        },

        taskId(): number {
            return parseInt(this.route.query?.task as string) || 0;
        },

        board(): IBoard | undefined {
            return store.state.boards?.find((board) => board.id === this.task?.boardId);
        },

        project(): IProject | undefined {
            return store.state.projects?.find((project) => project.id === this.task?.projectId);
        },

        visible(): boolean {
            const visible = !!this.taskId;
            return visible;
        },

        canCreateSubTasks(): boolean {
            return this.task?.type === TaskType.Task;
        },
    },

    methods: {
        close(force = false): void {
            this.router.replace({
                query: {
                    ...this.route.query,
                    prevTask: undefined,
                    prevSelected: undefined,
                    task: force ? undefined : this.route.query.prevTask,
                    selected: force ? undefined : this.route.query.prevSelected,
                },
            });
        },

        async fetchTask(): Promise<void> {
            this.loading = true;

            try {
                const result = await TaskService.queryAsync({
                    whereId: [this.taskId],
                    whereType: [TaskType.Task, TaskType.Subtask],
                    withArchived: true,
                    perPage: 1,
                    includes: ['stories', 'approvements', 'collaborators', 'save-points-count'],
                });

                const task = result.length > 0 ? result[0] : null;

                if (!task) {
                    return;
                }

                if (task.parentId) {
                    await this.fetchParent(task.parentId);
                }

                this.task = task;
                this.subtasks = null;

                UserMapper.mapTaskAsync(task);
                ObjectStorageMapper.mapTaskAsync(task);

                // Creates the mutation context for watching for task.
                const contexts: IMutatorContext[] = [
                    new TaskMutatorContext([this.task], {
                        mapUsers: true,
                        mapAttachments: true,
                        ignoreTaskCreating: () => true,
                    }),
                ];

                // Loads the subtasks if the task can contains the subtasks.
                if (this.task.type === TaskType.Task) {
                    this.subtasks = await TaskService.queryAsync({
                        whereTaskId: [this.taskId],
                        whereType: [TaskType.Subtask],
                        includes: ['comments-count', 'attachments-count', 'collaborators', 'approvements'],
                    });

                    UserMapper.mapTasksAsync(this.subtasks);

                    contexts.push(
                        new TaskMutatorContext(this.subtasks, {
                            mapUsers: true,
                            ignoreTaskCreating: (task) => task.parentId !== this.task?.id,
                            excludeTask: (task) => !!task.archivedAt,
                        }),
                    );
                }

                // Deactivate old context, to avoid memory leaks.
                MutationBus.deactivate(this.mutatorContexts);
                this.mutatorContexts = markRaw(contexts);
                MutationBus.activate(this.mutatorContexts);

                this.$nextTick(() =>
                    emitter.emit(EventNames.TaskPanelOpened, {
                        task,
                        taskId: task.id,
                        clientWidth: this.$el.clientWidth + 2,
                    }),
                );
            } catch (error) {
                $error(error);
            } finally {
                this.loading = false;
            }
        },

        async fetchParent(parentId: number): Promise<void> {
            const tasks = await TaskService.queryAsync({
                whereId: [parentId],
                perPage: 1,
                withArchived: true,
            });

            this.parent = tasks.length > 0 ? tasks[0] : null;
        },

        async onDocumentClick(event: MouseEvent): Promise<void> {
            if (!this.visible) {
                return;
            }

            const element = event.target as HTMLElement;

            let stopCloseEvent = isClickOnScrollbar(event);
            if (stopCloseEvent) {
                return;
            }

            stopCloseEvent = hasParentWithClassName(element, ['prevent-close', 'splitpanes__splitter']);
            if (stopCloseEvent) {
                return;
            }

            this.close(true);
        },

        beforeOpen(): Promise<void> {
            pushPageTitle(this.t('task-number', { number: this.taskId }));

            this.triggerBeforeOpenHook();

            return this.fetchTask();
        },

        beforeClose(): void {
            this.triggerBeforeClosedHook();

            this.task = null;
            this.parent = null;
            this.subtasks = null;

            // Deactivate old context, to avoid memory leaks.
            MutationBus.deactivate(this.mutatorContexts);
        },

        onVisibleChanged(taskId: number, oldTaskId: number): void {
            pullPageTitle(this.t('task-number', { number: oldTaskId }));

            if (!taskId) {
                this.beforeClose();
            } else if (taskId != this.task?.id) {
                this.beforeOpen();
            }
        },

        onParentChanged(newValue: number, oldValue: number): void {
            if (!this.task?.parentId) {
                this.parent = null;
            }

            if (newValue !== oldValue && this.task?.parentId && this.parent?.id !== newValue) {
                this.fetchParent(this.task?.parentId);
            }
        },

        triggerBeforeOpenHook(): void {
            this.$nextTick(() =>
                emitter.emit(EventNames.TaskPanelBeforeOpen, {
                    taskId: this.taskId,
                    clientWidth: this.$el.clientWidth + 2,
                }),
            );
        },

        triggerBeforeClosedHook() {
            emitter.emit(EventNames.TaskPanelClosed, { taskId: this.taskId });
        },
    },

    watch: {
        'taskId': {
            handler: 'onVisibleChanged',
        },
        'task.parentId': {
            handler: 'onParentChanged',
            deep: true,
        },
    },

    mounted(): void {
        if (this.visible) {
            this.beforeOpen();
        }

        document.addEventListener('mousedown', this.onDocumentClick);
    },

    created() {
        this.removeAfterEachListener = this.router.afterEach(this.triggerBeforeOpenHook);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchTask);
    },

    beforeUnmount(): void {
        // Deactivate old context, to avoid memory leaks.
        MutationBus.deactivate(this.mutatorContexts);

        if (this.removeAfterEachListener) {
            this.removeAfterEachListener();
        }

        document.removeEventListener('mousedown', this.onDocumentClick);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchTask);
    },
});
</script>

<style lang="scss">
.home-task-panel {
    &__content {
        display: flex;
        width: 100%;
        height: 100%;

        &--center {
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
