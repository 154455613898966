import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "task-panel" }
const _hoisted_2 = { class: "task-panel__scroll" }
const _hoisted_3 = {
  key: 0,
  class: "task-panel__row task-panel__row--center task-panel__row--padding task-panel__row--secondary"
}
const _hoisted_4 = { class: "task-panel__alert" }
const _hoisted_5 = { class: "task-panel__row task-panel__row--first task-panel__row--close-button-space" }
const _hoisted_6 = {
  key: 1,
  class: "task-panel__row task-panel__row--padding"
}
const _hoisted_7 = {
  key: 2,
  class: "task-panel__row task-panel__row--padding"
}
const _hoisted_8 = { class: "task-panel__row task-panel__row--padding task-panel__row--separate" }
const _hoisted_9 = { class: "info-panel task-panel__info-panel" }
const _hoisted_10 = { class: "info-panel__row" }
const _hoisted_11 = { class: "task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right" }
const _hoisted_12 = { class: "info-panel__content info-panel__content--vcenter info-panel__content--buttons" }
const _hoisted_13 = { class: "info-panel__row" }
const _hoisted_14 = { class: "task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right" }
const _hoisted_15 = { class: "info-panel__content info-panel__content--vcenter" }
const _hoisted_16 = { class: "info-panel__row" }
const _hoisted_17 = { class: "task-panel__label info-panel__label info-panel__label--vcenter required inline-space-75-right" }
const _hoisted_18 = { class: "info-panel__content" }
const _hoisted_19 = { class: "task-panel__row task-panel__row--padding task-panel__row--separate" }
const _hoisted_20 = { class: "info-panel" }
const _hoisted_21 = { class: "info-panel__row" }
const _hoisted_22 = { class: "task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right" }
const _hoisted_23 = { class: "info-panel__content info-panel__content--vcenter info-panel__content--overflow-hidden" }
const _hoisted_24 = { class: "info-panel__row" }
const _hoisted_25 = { class: "task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right required" }
const _hoisted_26 = { class: "info-panel__row" }
const _hoisted_27 = { class: "task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right" }
const _hoisted_28 = { class: "info-panel__content info-panel__content--vcenter info-panel__content--overflow-hidden" }
const _hoisted_29 = { class: "info-panel__row" }
const _hoisted_30 = { class: "task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right" }
const _hoisted_31 = { class: "info-panel__content info-panel__content--vcenter info-panel__content--overflow-hidden" }
const _hoisted_32 = { class: "task-panel__row task-panel__row--padding task-panel__row--separate" }
const _hoisted_33 = { class: "task-panel__label task-panel__label--separate required" }
const _hoisted_34 = { class: "task-panel__row task-panel__row--padding task-panel__row--separate" }
const _hoisted_35 = { class: "task-panel__label task-panel__label--separate-x2" }
const _hoisted_36 = {
  key: 3,
  class: "task-panel__row task-panel__row--padding task-panel__row--separate"
}
const _hoisted_37 = { class: "task-panel__header task-panel__header--title task-panel__header--with-button" }
const _hoisted_38 = { class: "task-panel__subtasks" }
const _hoisted_39 = {
  key: 4,
  class: "task-panel__row task-panel__row--padding"
}
const _hoisted_40 = { class: "task-panel__header task-panel__header--title" }
const _hoisted_41 = {
  key: 5,
  class: "task-panel__row task-panel__row--flex task-panel__row--padding"
}
const _hoisted_42 = { class: "task-panel__header task-panel__header--title task-panel__header--selector inline-75-x" }
const _hoisted_43 = {
  key: 0,
  class: "task-panel__chat"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoticeSvg = _resolveComponent("NoticeSvg")!
  const _component_VTextarea = _resolveComponent("VTextarea")!
  const _component_TimesSvg = _resolveComponent("TimesSvg")!
  const _component_VButton = _resolveComponent("VButton")!
  const _component_VInlineLink = _resolveComponent("VInlineLink")!
  const _component_VTaskStatus = _resolveComponent("VTaskStatus")!
  const _component_DotsSvg = _resolveComponent("DotsSvg")!
  const _component_VButtonDropdown = _resolveComponent("VButtonDropdown")!
  const _component_VSelect = _resolveComponent("VSelect")!
  const _component_VDatePicker = _resolveComponent("VDatePicker")!
  const _component_VUsersList = _resolveComponent("VUsersList")!
  const _component_VContentEditable = _resolveComponent("VContentEditable")!
  const _component_VFileList = _resolveComponent("VFileList")!
  const _component_PlusSvg = _resolveComponent("PlusSvg")!
  const _component_VTaskCardInline = _resolveComponent("VTaskCardInline")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_VApprovementList = _resolveComponent("VApprovementList")!
  const _component_VFeedStory = _resolveComponent("VFeedStory")!
  const _component_VChatInput = _resolveComponent("VChatInput")!
  const _component_VUserSelector = _resolveComponent("VUserSelector")!
  const _component_VTaskPanelLayer = _resolveComponent("VTaskPanelLayer")!
  const _component_VCollaboratorsList = _resolveComponent("VCollaboratorsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.task.archivedAt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_NoticeSvg, { class: "task-panel__alert-icon" }),
            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.t('task-archived')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_VTextarea, {
          class: _normalizeClass(["simple-textarea--h2", {
                        error: _ctx.formErrors?.title,
                    }]),
          required: "",
          "debounce-mode": "",
          "concurrency-mode": "",
          maxlength: "512",
          editable: _ctx.editable,
          disabled: !_ctx.canUpdate || _ctx.disabled,
          "model-value": _ctx.task.title,
          onEditRequest: _ctx.confirmToDraft,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTitleAsync(_ctx.task, $event)))
        }, null, 8, ["class", "editable", "disabled", "model-value", "onEditRequest"]),
        _createVNode(_component_VButton, {
          class: "button--rounded button--transparent task-panel__button task-panel__button--close",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TimesSvg)
          ]),
          _: 1
        })
      ]),
      (_ctx.isSubtask)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.parent)
              ? (_openBlock(), _createBlock(_component_VInlineLink, {
                  key: 0,
                  class: "prevent-close",
                  to: {
                        query: {
                            task: _ctx.parent.id,
                        },
                    },
                  subject: _ctx.t('attached-to-task')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.parent.title ? _ctx.parent.title : _ctx.t('untitled')), 1)
                  ]),
                  _: 1
                }, 8, ["to", "subject"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.project && _ctx.board)
              ? (_openBlock(), _createBlock(_component_VInlineLink, {
                  key: 0,
                  class: "prevent-close",
                  to: {
                        name: 'projects.board',
                        params: {
                            projectId: _ctx.project.id,
                            boardId: _ctx.board.id,
                        },
                        query: {
                            task: _ctx.task.id,
                        },
                    },
                  subject: _ctx.t('board-link-text')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.tryTranslate(_ctx.board.title)) + ", " + _toDisplayString(_ctx.tryTranslate(_ctx.project.title)), 1)
                  ]),
                  _: 1
                }, 8, ["to", "subject"]))
              : _createCommentVNode("", true)
          ])),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("ul", _hoisted_9, [
          _createElementVNode("li", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('status')), 1),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_VTaskStatus, {
                class: "status--space-right",
                task: _ctx.task
              }, null, 8, ["task"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (action.isMainAction)
                    ? (_openBlock(), _createBlock(_component_VButton, {
                        key: 0,
                        class: _normalizeClass(action.panelClasses),
                        onClick: action.action
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(action.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "onClick"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              (_ctx.actions.length)
                ? (_openBlock(), _createBlock(_component_VButtonDropdown, {
                    key: 0,
                    options: _ctx.actions,
                    placement: "left-start"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DotsSvg)
                    ]),
                    _: 1
                  }, 8, ["options"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("li", _hoisted_13, [
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.parentTitle), 1),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_VSelect, {
                class: "v-select--primary inline-50-x inline-fluid",
                label: "title",
                placeholder: _ctx.t('default-placeholder', 10),
                reduce: _ctx.getOptionValue,
                options: _ctx.parentOptions,
                disabled: !_ctx.canUpdate || _ctx.disabled || _ctx.isSubtask,
                "model-value": _ctx.task.parentId,
                onOpen: _ctx.fetchParentsOptionsIfNotExistsOrStartEdit,
                onSearch: _ctx.fetchParentsOptions,
                "onOption:selected": _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeParentTaskAsync(_ctx.task, $event.value)))
              }, {
                "selected-option": _withCtx((option) => [
                  (_ctx.parent && option && _ctx.parentLink)
                    ? (_openBlock(), _createBlock(_component_VInlineLink, {
                        key: 0,
                        class: "inline-link--selected-option",
                        to: _ctx.parentLink,
                        onMousedown: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.parent.title ? _ctx.parent.title : _ctx.t('untitled')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["placeholder", "reduce", "options", "disabled", "model-value", "onOpen", "onSearch"])
            ])
          ]),
          _createElementVNode("li", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('deadline')), 1),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_VDatePicker, {
                class: _normalizeClass(["inline-50-x inline-fluid", {
                                    error: _ctx.formErrors?.deadline,
                                }]),
                editable: _ctx.editable,
                disabled: !_ctx.canUpdate || _ctx.disabled,
                "model-value": _ctx.task.deadline,
                onEditRequest: _ctx.confirmToDraft,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeDeadlineAsync(_ctx.task, $event)))
              }, null, 8, ["class", "editable", "disabled", "model-value", "onEditRequest"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("ul", _hoisted_20, [
          _createElementVNode("li", _hoisted_21, [
            _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('author')), 1),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_VUsersList, {
                users: [_ctx.task.author!],
                editable: _ctx.canUpdateAuthor && !_ctx.disabled,
                disabled: !_ctx.canUpdateAuthor || _ctx.disabled,
                onOpen: _ctx.showAuthorSelector
              }, null, 8, ["users", "editable", "disabled", "onOpen"])
            ])
          ]),
          _createElementVNode("li", _hoisted_24, [
            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t('assignee')), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["info-panel__content info-panel__content--vcenter info-panel__content--overflow-hidden press-50-x inline-50-x", {
                                error: _ctx.formErrors?.collaborators,
                            }])
            }, [
              _createVNode(_component_VUsersList, {
                users: _ctx.assignees,
                editable: _ctx.canManageCollaborators && _ctx.editable,
                disabled: !_ctx.canManageCollaborators || _ctx.disabled,
                onEditRequest: _ctx.confirmToDraft,
                onOpen: _ctx.showMangers
              }, null, 8, ["users", "editable", "disabled", "onEditRequest", "onOpen"])
            ], 2)
          ]),
          _createElementVNode("li", _hoisted_26, [
            _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t('contributors')), 1),
            _createElementVNode("div", _hoisted_28, [
              _createVNode(_component_VUsersList, {
                users: _ctx.contributors,
                editable: _ctx.canManageCollaborators,
                disabled: !_ctx.canManageCollaborators || _ctx.disabled,
                onOpen: _ctx.showContributors
              }, null, 8, ["users", "editable", "disabled", "onOpen"])
            ])
          ]),
          _createElementVNode("li", _hoisted_29, [
            _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('collaborators')), 1),
            _createElementVNode("div", _hoisted_31, [
              _createVNode(_component_VUsersList, {
                users: _ctx.collaborators,
                editable: _ctx.canManageCollaborators,
                disabled: !_ctx.canManageCollaborators || _ctx.disabled,
                onOpen: _ctx.showCollaborators
              }, null, 8, ["users", "editable", "disabled", "onOpen"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t('description')), 1),
        _createVNode(_component_VContentEditable, {
          class: _normalizeClass(["simple-contenteditable--unlimited simple-contenteditable--great inline-100-x inline-fluid", {
                        error: _ctx.formErrors?.description,
                    }]),
          "debounce-mode": "",
          "concurrency-mode": "",
          maxlength: "4096",
          editable: _ctx.editable,
          disabled: !_ctx.canUpdate || _ctx.disabled,
          "model-value": _ctx.task.description,
          onEditRequest: _ctx.confirmToDraft,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeDescriptionAsync(_ctx.task, $event)))
        }, null, 8, ["class", "editable", "disabled", "model-value", "onEditRequest"])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.t('attachments')), 1),
        _createVNode(_component_VFileList, {
          attachments: _ctx.attachments,
          uploader: _ctx.uploader,
          onRemoved: _cache[6] || (_cache[6] = ($event: any) => (_ctx.removeStoryAsync(_ctx.task, $event))),
          onUploaded: _ctx.uploadedAttachmentHandler
        }, null, 8, ["attachments", "uploader", "onUploaded"])
      ]),
      ((_ctx.subtasks && _ctx.subtasks.length) || _ctx.canCreateSubTasks)
        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
            _createElementVNode("h3", _hoisted_37, [
              _createTextVNode(_toDisplayString(_ctx.t('subtasks')) + " ", 1),
              (_ctx.canCreateSubTasks)
                ? (_openBlock(), _createBlock(_component_VButton, {
                    key: 0,
                    class: "button--secondary button--vcenter button--transparent task-panel__header-button",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.createSubtaskAsync(_ctx.task)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PlusSvg),
                      _createTextVNode(" " + _toDisplayString(_ctx.t('add-subtask')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_38, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subtasks, (subtask, index) => {
                return (_openBlock(), _createBlock(_component_RouterLink, {
                  key: index,
                  to: {
                                query: { task: subtask.id, prevTask: _ctx.task.id, prevSelected: _ctx.route.query.selected },
                            }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_VTaskCardInline, {
                      class: "task-panel__subtask",
                      inline: "",
                      task: subtask
                    }, null, 8, ["task"])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.task.approvements)
        ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
            _createElementVNode("h3", _hoisted_40, _toDisplayString(_ctx.t('approval-list-title')), 1),
            _createVNode(_component_VApprovementList, {
              disabled: _ctx.disabled,
              "model-value": _ctx.task.approvements,
              onApprove: _cache[8] || (_cache[8] = ($event: any) => (_ctx.approveAsync(_ctx.task, $event))),
              onDisapprove: _cache[9] || (_cache[9] = ($event: any) => (_ctx.disapproveAsync(_ctx.task, $event)))
            }, null, 8, ["disabled", "model-value"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.task.stories)
        ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
            _createElementVNode("h3", _hoisted_42, [
              _createVNode(_component_VSelect, {
                class: "v-select--primary v-select--shrunken",
                label: "title",
                reduce: _ctx.getOptionValue,
                placeholder: _ctx.t('default-placeholder', 2),
                options: _ctx.viewModeOptions,
                modelValue: _ctx.storyViewMode,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.storyViewMode) = $event))
              }, null, 8, ["reduce", "placeholder", "options", "modelValue"])
            ]),
            _createVNode(_component_VFeedStory, {
              class: "task-panel__stories",
              title: "",
              selectable: "",
              task: _ctx.task,
              stories: _ctx.task.stories,
              "view-mode": _ctx.storyViewMode,
              onStartEditStory: _ctx.startEdit,
              onRemoveStory: _cache[11] || (_cache[11] = ($event: any) => (_ctx.removeStoryAsync(_ctx.task, $event)))
            }, null, 8, ["task", "stories", "view-mode", "onStartEditStory"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.canComment)
      ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
          _createVNode(_component_VChatInput, {
            uploader: _ctx.uploader,
            onUpdate: _ctx.updateComment,
            onSubmit: _ctx.createComment,
            onStopEdit: _ctx.stopEdit,
            onStartEdit: _ctx.startEdit,
            newModel: _ctx.newComment,
            "onUpdate:newModel": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newComment) = $event)),
            editModel: _ctx.editComment,
            "onUpdate:editModel": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editComment) = $event))
          }, null, 8, ["uploader", "onUpdate", "onSubmit", "onStopEdit", "onStartEdit", "newModel", "editModel"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_VTaskPanelLayer, {
      header: "",
      visible: _ctx.showAuthorLayer,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showAuthorLayer) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_VUserSelector, {
          autofocus: "",
          "actions-margin": "",
          title: _ctx.t('author'),
          "cancel-button-title": _ctx.t('cancel'),
          "submit-button-title": _ctx.t('apply'),
          onCancel: _ctx.hideAuthorSelector,
          onSubmit: _ctx.setAuthor,
          modelValue: _ctx.showAuthorLayerAuthors,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.showAuthorLayerAuthors) = $event))
        }, null, 8, ["title", "cancel-button-title", "submit-button-title", "onCancel", "onSubmit", "modelValue"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_VTaskPanelLayer, {
      header: "",
      visible: _ctx.showApproversLayer,
      "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.showApproversLayer) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_VUserSelector, {
          multiple: "",
          deletable: "",
          autofocus: "",
          "actions-margin": "",
          title: _ctx.t('approvers'),
          "cancel-button-title": _ctx.t('cancel'),
          "submit-button-title": _ctx.t('send-for-approvement'),
          onCancel: _ctx.hideApproverSelector,
          onSubmit: _ctx.startApprovementProcess,
          modelValue: _ctx.showApproversLayerApprovers,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.showApproversLayerApprovers) = $event))
        }, null, 8, ["title", "cancel-button-title", "submit-button-title", "onCancel", "onSubmit", "modelValue"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_VTaskPanelLayer, {
      header: "",
      visible: _ctx.showCollaboratorsLayer,
      "onUpdate:visible": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.showCollaboratorsLayer) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_VCollaboratorsList, {
          title: "",
          autofocus: "",
          role: _ctx.showCollaboratorsLayerContext!.role,
          taskId: _ctx.showCollaboratorsLayerContext!.taskId,
          multiple: _ctx.showCollaboratorsLayerContext!.multiple,
          disabled: !_ctx.canManageCollaborators || _ctx.disabled,
          collaborators: _ctx.task.collaborators,
          onEditRequest: _ctx.confirmToDraft,
          onAdded: _cache[18] || (_cache[18] = ($event: any) => (_ctx.addCollaboratorAsync(_ctx.task, $event))),
          onRemoved: _cache[19] || (_cache[19] = ($event: any) => (_ctx.removeCollaboratorAsync(_ctx.task, $event)))
        }, null, 8, ["role", "taskId", "multiple", "disabled", "collaborators", "onEditRequest"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}