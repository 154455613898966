import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["row-index"]
const _hoisted_2 = {
  key: 0,
  class: "v-table__content v-table__content--text"
}

import { PropType } from 'vue';
import ITable, { ITableColumn } from '@/core/Values/ITable';
import ITask from '@/core/Models/ITask';


export default /*@__PURE__*/_defineComponent({
  __name: 'VTableRow',
  props: {
    table: {
        type: Object as PropType<ITable>,
        required: true,
    },
    data: {
        type: Object as PropType<unknown>,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    active: {
        type: Boolean,
        default: false,
    },
},
  setup(__props) {

// Svg
// ...

// Components

// Other
const props = __props;

function getCellWidth(table: ITable<ITask>, cell: ITableColumn<ITask>) {
    return { width: (cell.width ? cell.width : 100 / table.columns.length) + '%' };
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["v-table__row v-table__row--hover", {
            'v-table__row--active': props.active,
        }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.table.columns, (column, cellIndex) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: column.title
      }, [
        (column.visible)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: _normalizeClass(["v-table__column v-table__column--border v-table__column--borderless", {
                    'v-table__column--indexed': cellIndex === 0,
                }]),
              style: _normalizeStyle(getCellWidth(props.table, column)),
              "row-index": __props.index + 1
            }, [
              (column.text)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(column.text(__props.data, __props.index)), 1))
                : (column.render)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(column.render(__props.data, __props.index)), { key: 1 }))
                  : (column.component)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(column.component), {
                        key: 2,
                        data: __props.data,
                        index: __props.index
                      }, null, 8, ["data", "index"]))
                    : _createCommentVNode("", true)
            ], 14, _hoisted_1))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 2))
}
}

})