<template>
    <VPage :class="{ 'app-page--fullscreen': pageSettings.fullscreen }">
        <VPageHeader show-third-block :title="pageTitle">
            <template v-slot:second>
                <div class="app-page-header__column app-page-header__column--tools">
                    <VPageSettings class="prevent-close" :options="pageOptions" v-model="pageSettings"></VPageSettings>

                    <VTaskFilter
                        class="prevent-close"
                        :filter-types="[
                            TaskFilterTypes.Author,
                            TaskFilterTypes.Status,
                            TaskFilterTypes.Assignee,
                            TaskFilterTypes.Contributor,
                            TaskFilterTypes.AuthorOrAssignee,
                            TaskFilterTypes.Deadline,
                            TaskFilterTypes.CreationDate,
                        ]"
                        v-model="filters"
                    ></VTaskFilter>

                    <VSearchField
                        class="prevent-close"
                        debounce-mode
                        v-model="searchString"
                        @update:model-value="fetchData"
                    ></VSearchField>
                </div>
            </template>
        </VPageHeader>

        <VPageContent :vertical="pageSettings.viewMode === 'table'">
            <VBoard v-if="pageSettings.viewMode === 'board'">
                <template v-for="(column, columnIndex) in columns" :key="columnIndex">
                    <VColumn wheel-propagation :class="{ 'column--goals': isGoalsColumn(column) }" :column="column">
                        <ul tag="ul" class="column__list">
                            <template v-for="task in groupedTasks[column.boardId]" :key="task.id">
                                <li class="column__item">
                                    <template v-if="isGoalsColumn(column)">
                                        <RouterLink :to="{ name: 'goals.view', params: { goalId: task.id } }">
                                            <VGoalCard :goal="task"></VGoalCard>
                                        </RouterLink>
                                    </template>

                                    <template v-else>
                                        <RouterLink :to="{ query: { task: task.id } }">
                                            <VTaskCard :task="task"></VTaskCard>
                                        </RouterLink>
                                    </template>
                                </li>
                            </template>
                        </ul>
                    </VColumn>
                </template>
            </VBoard>

            <VTable v-model:table="table" v-model:order="order" v-else-if="pageSettings.viewMode === 'table'">
                <template v-for="(column, columnIndex) in columns" :key="columnIndex">
                    <VTableDataSet :title="column.title" v-if="getTasks(column).length">
                        <template v-for="(task, index) in groupedTasks[column.boardId]" :key="task.id">
                            <RouterLink
                                class="prevent-close"
                                :to="
                                    isGoalsColumn(column)
                                        ? { name: 'goals.view', params: { goalId: task.id } }
                                        : { query: { task: task.id } }
                                "
                            >
                                <VTableRow
                                    :table="table"
                                    :data="task"
                                    :index="index"
                                    :active="task.id.toString() == route.query.task"
                                    @contextmenu="openContextMenu($event, task)"
                                ></VTableRow>
                            </RouterLink>
                        </template>
                    </VTableDataSet>
                </template>
            </VTable>
        </VPageContent>
    </VPage>
</template>

<script lang="ts">
// Components
import VGoalCard from '../components/VGoalCard.vue';
import VTaskCard from '../components/VTaskCard.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VBoard from '@/components/VBoard.vue';
import VColumn from '../components/VColumn.vue';
import VTable from '../components/VTable.vue';
import VTableRow from '../components/VTableRow.vue';
import VTableDataSet from '../components/VTableDataSet.vue';
import VPageSettings from '../components/VPageSettings.vue';

// Other
import { setPageTitle } from '@/utils/document-utils';
import store from '@/store';
import IUser from '@/core/Models/IUser';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import scrollIntoView from 'scroll-into-view';
import IProject from '@/core/Models/IProject';
import UserMapper from '@/core/UserMapper';
import TaskType from '@/core/Values/TaskType';
import IColumn from '@/core/Models/IColumn';
import { Raw, markRaw, toRaw } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import ProjectBoardType from '@/core/Values/ProjectBoardType';
import orderBy from 'lodash.orderby';
import { defineComponent } from 'vue';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import useTaskTableViewer, { defaultColumns } from '@/mixins/TaskTableViewer';
import { usePageOptions } from '@/mixins/PageSettings';
import { IOrder } from '@/core/Values/IOrder';
import IPageSettings from '@/core/Values/IPageSettings';

export default defineComponent({
    components: {
        VGoalCard,
        VTaskCard,
        VBoard,
        VColumn,
        VPage,
        VPageHeader,
        VPageContent,
        VTaskFilter,
        VSearchField,
        VTable,
        VTableRow,
        VTableDataSet,
        VPageSettings,
    },
    data: () => ({
        pageTitle: '',
        searchString: '',
        tasks: [] as ITask[],
        mutatorContext: null as Raw<IMutatorContext> | null,

        TaskFilterTypes: markRaw(TaskFilterTypes),
    }),
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        const contextMenu = useTaskContextMenu();
        const table = useTaskTableViewer(Settings.UI.Table + '.project.view', {
            header: true,
            columns: defaultColumns,
        });
        const defaultPageOptions = usePageOptions();
        const pageOptions = [defaultPageOptions.viewMode, defaultPageOptions.fullscreen];

        return {
            t,
            route,
            router,

            table,
            pageOptions,
            ...contextMenu,
        };
    },
    computed: {
        order: Storages.Filters.computed<IOrder>(Settings.UI.Order + '.project.view', {
            orderBy: 'id',
            orderAscending: false,
        }),
        filters: Storages.Filters.computed(Settings.UI.Filters + '.project.view', { value: [], formattedValue: {} }),
        pageSettings: Storages.Settings.computed<IPageSettings>(Settings.UI.PageSettings + '.project.view', {
            viewMode: 'board',
            fullscreen: false,
        }),

        projectId(): number {
            return parseInt(this.route.params.projectId as string, 10);
        },

        currentUser(): IUser | null {
            return store.state.user;
        },

        project(): IProject | undefined {
            return store.state.projects?.find((project) => project.id == this.projectId);
        },

        columns(): IColumn[] {
            let columns: IColumn[] = [];

            if (!this.project) {
                return columns;
            }

            if (!store.state.boards?.length) {
                return columns;
            }

            const projectTitle = this.$tryTranslate(this.project.title);
            columns = store.state.boards
                .filter((board) => board.projectId === this.project?.id)
                .map((board): IColumn => {
                    const boardTitle = this.$tryTranslate(board.title);

                    const title = boardTitle + ', ' + projectTitle;
                    return {
                        id: -1,
                        title: title,
                        order: 100000 - (board?.type ?? 0),
                        board,
                        boardId: board.id,
                        project: this.project,
                        projectId: board.projectId,
                    };
                });

            return orderBy(columns, (column) => column.order + (column.orderStr ?? ''));
        },

        tasksFilters(): QueryTaskRequest {
            return {
                ...this.order,
                ...this.filters.formattedValue,
                whereType: [TaskType.Task, TaskType.Goal],
                whereProjectId: [this.project?.id as number],
                includes: ['stories', 'approvements', 'collaborators'],
                search: this.searchString,
            };
        },

        groupedTasks(): Record<number, ITask[]> {
            return this.columns.reduce((carry: Record<number, ITask[]>, column) => {
                // The order was marked as raw for not tracking changes.
                carry[column.boardId] = orderBy(
                    this.getTasks(column),
                    toRaw(this.order).orderBy,
                    toRaw(this.order).orderAscending ? 'asc' : 'desc',
                );

                return carry;
            }, {} as Record<number, ITask[]>);
        },
    },
    methods: {
        getTasks(column: IColumn): ITask[] {
            if (!this.tasks?.length) {
                return [];
            }

            return this.tasks.filter((task) => task.boardId === column.boardId) ?? [];
        },

        isGoalsColumn(column: IColumn): boolean {
            return column.board?.type === ProjectBoardType.Goals;
        },

        async fetchData(): Promise<void> {
            if (!this.project) {
                return;
            }

            this.pageTitle = this.$tryTranslate(this.project.title);
            this.tasks = await TaskService.queryAsync(this.tasksFilters);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.tasks);
            ObjectStorageMapper.mapTasksAsync(this.tasks);

            // Deactivate old context, to avoid memory leaks.
            MutationBus.deactivate(this.mutatorContext);
            this.mutatorContext = markRaw(
                new TaskMutatorContext(this.tasks, {
                    mapUsers: true,
                    mapPreview: true,
                    // Fetches a task if the patch contains the changes that can affect of the filters.
                    fetchTask: async (patch: Partial<ITask>) => {
                        if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.tasksFilters)) {
                            return undefined;
                        }

                        const tasks = await TaskService.queryAsync({
                            ...this.tasksFilters,
                            whereId: [patch.id as number],
                            perPage: 1,
                        });

                        if (!tasks.length) {
                            return undefined;
                        }

                        return UserMapper.mapTaskAsync(tasks[0]);
                    },

                    // Excludes tasks if they are not matching the specified filters.
                    excludeTask: (task: ITask) =>
                        !TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                    // Ignores tasks creation if they are not matching the specified filters.
                    ignoreTaskCreating: (task: ITask) =>
                        TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                }),
            );
            MutationBus.activate(this.mutatorContext);

            setPageTitle(this.pageTitle);
        },

        TaskPanelBeforeOpen(event: { taskId: number; clientWidth: number }): void {
            this.$nextTick(() => {
                const taskElement = document.querySelector(`.task-card[task-id='${event.taskId}']`) as HTMLElement;
                if (taskElement) {
                    scrollIntoView(taskElement, {
                        time: 250,
                    });
                }
            });
        },
    },

    watch: {
        tasksFilters: 'fetchData',
    },

    created(): void {
        this.fetchData();

        emitter.on(EventNames.TaskPanelBeforeOpen, this.TaskPanelBeforeOpen);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    beforeUnmount(): void {
        // Deactivate old context, to avoid memory leaks.
        MutationBus.deactivate(this.mutatorContext);

        emitter.off(EventNames.TaskPanelBeforeOpen, this.TaskPanelBeforeOpen);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
</script>
