<template>
    <div class="feed-story">
        <ul
            ref="scroll"
            class="feed-story__list"
            :class="{
                'feed-story__list--scroll': scrollable,
            }"
            @scroll="onScroll"
        >
            <!-- Show other records -->
            <template v-for="(record, index) in records" :key="record.id">
                <template v-if="index === 0">
                    <!-- Shows the first record if exists -->
                    <li class="feed-story__item">
                        <VFeedStoryRecord :record="record" :selectable="selectable"></VFeedStoryRecord>
                    </li>

                    <!-- Show more button -->
                    <li
                        class="feed-story__item feed-story__item--offset feed-story__item--center"
                        v-if="showMoreButton"
                    >
                        <VButton class="button--default button--secondary" @click="showMore">
                            <template v-if="hiddenRecordsCount < showMoreCount">
                                {{ t('show-all-stories') }}
                            </template>

                            <template v-else>
                                {{ t('show-more-stories', [showMoreCount, hiddenRecordsCount]) }}
                            </template>
                        </VButton>
                    </li>
                </template>

                <template v-else>
                    <li class="feed-story__item">
                        <VFeedStoryRecord :record="record" :selectable="selectable"></VFeedStoryRecord>
                    </li>
                </template>
            </template>
        </ul>
    </div>
</template>

<script setup lang="ts">
// Svg
import EditSvg from '@/assets/edit.svg';
import RemoveSvg from '@/assets/remove.svg';

// Components
import VButton from './VButton.vue';
import VFeedStoryRecord from './VFeedStoryRecord.vue';

// Other
import IStory from '@/core/Models/IStory';
import ITask from '@/core/Models/ITask';
import IFeedStoryRecord from '@/core/Values/IFeedStoryRecord';
import StoryType from '@/core/Values/StoryType';
import { DateTime } from 'luxon';
import { markRaw, PropType, defineProps, ref, computed, onMounted, nextTick, watch, defineEmits } from 'vue';
import orderBy from 'lodash.orderby';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { fileExtension } from '@/utils/utils';
import StoryViewMode from '@/core/Values/StoryViewMode';
import { useI18n } from 'vue-i18n';
import { tryTranslate } from '@/plugins/VueI18n';

const { t } = useI18n();
const emit = defineEmits(['remove-story', 'start-edit-story']);
const props = defineProps({
    task: { type: Object as PropType<ITask>, required: true },
    stories: { type: Array as PropType<IStory[]>, default: () => [] },
    viewMode: { type: Number as PropType<StoryViewMode>, default: StoryViewMode.All },
    selectable: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    forceCollapseFirst: { type: Boolean, default: false },
});

let showCount = ref(10);
let showMoreCount = ref(10);
let followChat = ref(true);

const scroll = ref(null as HTMLDivElement | null);
const renderStories = computed(() => orderBy(props.stories.filter(viewModeFilter), (story) => story.id));
const showMoreButton = computed(() => hiddenRecordsCount.value > 0);
const hiddenRecordsCount = computed(() => Math.max(renderStories.value.length - showCount.value - 1, 0));
const records = computed(transformToRecords);

watch(records, () => {
    if (followChat.value) {
        nextTick(scrollToEnd);
    }
});
onMounted(() => nextTick(scrollToEnd));

function transformToRecords(): IFeedStoryRecord[] {
    if (!renderStories.value.length) {
        return [];
    }

    let lastStory: IStory | null = null;

    if (!renderStories.value.length) {
        return [];
    }

    // Takes the first story and the show number of stories from the end.
    const startIndex = -Math.min(showCount.value, renderStories.value.length) + 1;
    const stories =
        startIndex === 0
            ? renderStories.value.slice(0, 1)
            : renderStories.value.slice(0, 1).concat(renderStories.value.slice(startIndex));

    const records = stories.map((story, index): IFeedStoryRecord => {
        // records collapsing.
        const { collapsed, offset, hideActor } = getSharedOptions(lastStory, story);

        const record: IFeedStoryRecord = {
            id: story.id,
            actor: {
                id: story.actor?.id ?? '',
                picture: story.actor?.picture,
                displayName: story.actor?.displayName ?? 'Deleted user',
                weakColor: story.type === StoryType.Action,
                withPicture: story.type !== StoryType.Action,
                hideActor: hideActor,
            },
            offset: offset,
            padding: story.type !== StoryType.Action,
            timestamp: story.createdAt,
            // first 2 records cannot be collapsed
            collapsed: (props.forceCollapseFirst || index > 1) && collapsed,
            actions: getActions(story),
            content: story.text,
            contentEdited: !!story.editAt,
        };

        lastStory = story;

        if (story.type === StoryType.Attachment && story.downloadUri) {
            record.attachment = {
                id: story.id,
                authorId: story.actorId,
                fileName: story.fileName,
                objectName: story.objectName,
                extension: fileExtension(story.fileName),
                downloadUri: story.downloadUri,
            };
            record.content = '';
        }

        if (story.type === StoryType.Action) {
            record.header = {
                content: record.content ?? '',
            };
            record.content = '';
        }

        return record;
    });

    return records;
}

function showMore() {
    showCount.value = showCount.value + showMoreCount.value;
}

function onScroll(event: UIEvent) {
    const scroll = event.target as HTMLDivElement;
    followChat.value = scroll.scrollHeight - scroll.scrollTop === scroll.clientHeight;
}

function scrollToEnd() {
    if (scroll.value) {
        scroll.value.scrollTop = scroll.value.scrollHeight;
    }
}

function getActions(story: IStory): IDropdownOption[] | undefined {
    const canDelete = AuthorizationProvider.authorize(story, Operations.DeleteStory);
    const canUpdate = AuthorizationProvider.authorize(story, Operations.UpdateComment);

    const actions = [];

    if (canDelete && [StoryType.Comment, StoryType.Attachment].includes(story.type)) {
        actions.push({
            icon: markRaw(RemoveSvg),
            title: t('remove-story'),
            action: () => emit('remove-story', story),
        });
    }

    if (canUpdate && [StoryType.Comment].includes(story.type)) {
        actions.push({
            icon: markRaw(EditSvg),
            title: t('change-story'),
            action: () => emit('start-edit-story', story),
        });
    }

    return !actions.length ? undefined : actions;
}

function getSharedOptions(
    prevStory: IStory | null,
    nextStory: IStory,
): {
    offset: boolean;
    collapsed: boolean;
    hideActor: boolean;
} {
    if (!prevStory) {
        return {
            offset: false,
            collapsed: false,
            hideActor: false,
        };
    }

    const nextDate = DateTime.fromISO(nextStory.createdAt, { zone: 'UTC' });
    const prevDate = DateTime.fromISO(prevStory.createdAt, { zone: 'UTC' });

    const inSameTime = nextDate.diff(prevDate, 'minutes').minutes < 15;
    const isCollapsable = isCollapsableStoryType(nextStory.type);
    const isSameType = getUnionStoryType(prevStory.type) === getUnionStoryType(nextStory.type);
    const isSameActor = prevStory.actorId === nextStory.actorId;

    return {
        offset: nextStory.type !== prevStory.type && (!isSameActor || !isSameType),
        collapsed: isSameActor && isSameType && inSameTime && isCollapsable,
        hideActor: false,
    };
}

function viewModeFilter(story: IStory) {
    switch (props.viewMode) {
        case StoryViewMode.Actions:
            return story.type === StoryType.Action;
        case StoryViewMode.Comments:
            return story.type !== StoryType.Action;
        default:
            return true;
    }
}

function isCollapsableStoryType(storyType: StoryType) {
    switch (storyType) {
        case StoryType.Comment:
            return true;
        case StoryType.Attachment:
            return true;
        case StoryType.Action:
            return false;

        default:
            return false;
    }
}

function getUnionStoryType(storyType: StoryType) {
    switch (storyType) {
        case StoryType.Comment:
            return 1;
        case StoryType.Attachment:
            return 1;
        case StoryType.Action:
            return 2;

        default:
            return -1;
    }
}
</script>

<style lang="scss">
.feed-story {
    display: flex;
    height: 100%;
    min-height: 0;
    flex-direction: column;

    &__list {
        margin: 0;
        padding: 0 0 1.25rem 0;
        overflow: visible;

        &--scroll {
            overflow: auto;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    &__item {
        &--offset {
            margin: 0.5rem 0;
        }

        &--center {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
