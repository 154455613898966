<template>
    <VPage :class="{ 'app-page--fullscreen': pageSettings.fullscreen }">
        <VPageHeader :title="t('my-tasks')" show-third-block>
            <template v-slot:second>
                <div class="app-page-header__column app-page-header__column--tools">
                    <VPageSettings class="prevent-close" :options="pageOptions" v-model="pageSettings"></VPageSettings>

                    <VTaskFilter
                        class="prevent-close"
                        :filter-types="[
                            TaskFilterTypes.Author,
                            TaskFilterTypes.Status,
                            TaskFilterTypes.Assignee,
                            TaskFilterTypes.Contributor,
                            TaskFilterTypes.AuthorOrAssignee,
                            TaskFilterTypes.Deadline,
                            TaskFilterTypes.CreationDate,
                        ]"
                        v-model="filters"
                    ></VTaskFilter>

                    <VSearchField class="prevent-close" debounce-mode v-model="searchString"></VSearchField>
                </div>
            </template>
        </VPageHeader>

        <VPageContent vertical>
            <VBoard v-if="pageSettings.viewMode === 'board'">
                <template v-for="(column, columnIndex) in columns" :key="columnIndex">
                    <VColumn
                        wheel-propagation
                        :column="column"
                        v-if="column.projectId === -1 || groupedTasks[column.boardId]?.length"
                    >
                        <ul class="column__list">
                            <template v-for="task in groupedTasks[column.boardId]" :key="task.id">
                                <li class="column__item">
                                    <RouterLink class="prevent-close" :to="{ query: { task: task.id } }">
                                        <VTaskCard :task="task"></VTaskCard>
                                    </RouterLink>
                                </li>
                            </template>
                        </ul>
                    </VColumn>
                </template>
            </VBoard>

            <VTable v-model:table="table" v-model:order="order" v-else-if="pageSettings.viewMode === 'table'">
                <template v-for="(column, columnIndex) in columns" :key="columnIndex">
                    <VTableDataSet :title="column.title" v-if="groupedTasks[column.boardId].length">
                        <template v-for="(task, index) in groupedTasks[column.boardId]" :key="task.id">
                            <RouterLink class="prevent-close" :to="{ query: { task: task.id } }">
                                <VTableRow
                                    :table="table"
                                    :data="task"
                                    :index="index"
                                    :active="task.id.toString() == route.query.task"
                                    @contextmenu="openContextMenu($event, task)"
                                ></VTableRow>
                            </RouterLink>
                        </template>
                    </VTableDataSet>
                </template>
            </VTable>
        </VPageContent>
    </VPage>
</template>

<script lang="ts">
// Components
import VTaskCard from '../components/VTaskCard.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VPageSettings from '../components/VPageSettings.vue';
import VSearchField from '../components/VSearchField.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTable from '@/components/VTable.vue';
import VTableRow from '@/components/VTableRow.vue';
import VTableDataSet from '@/components/VTableDataSet.vue';
import VBoard from '@/components/VBoard.vue';
import VColumn from '../components/VColumn.vue';

// Other
import sortBy from 'lodash.sortby';
import { setPageTitle } from '@/utils/document-utils';
import store from '@/store';
import IUser from '@/core/Models/IUser';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import scrollIntoView from 'scroll-into-view';
import UserMapper from '@/core/UserMapper';
import TaskType from '@/core/Values/TaskType';
import IColumn from '@/core/Models/IColumn';
import { Raw, markRaw, ref, toRaw } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import Constants from '@/core/Constants';
import Status from '@/core/Values/Status';
import ProjectBoardType from '@/core/Values/ProjectBoardType';
import { defineComponent } from 'vue';
import orderBy from 'lodash.orderby';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { useI18n } from 'vue-i18n';
import { tryTranslate } from '@/plugins/VueI18n';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import useTaskTableViewer, { defaultColumns } from '@/mixins/TaskTableViewer';
import { useRoute } from 'vue-router';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import { usePageOptions } from '../mixins/PageSettings';
import IPageSettings from '@/core/Values/IPageSettings';
import { IOrder } from '@/core/Values/IOrder';

export default defineComponent({
    components: {
        VTaskCard,
        VTaskFilter,
        VPageSettings,
        VSearchField,
        VPage,
        VPageHeader,
        VPageContent,
        VTable,
        VTableRow,
        VTableDataSet,
        VBoard,
        VColumn,
    },

    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const contextMenu = useTaskContextMenu();
        const table = useTaskTableViewer(Settings.UI.Table + '.user.tasks', {
            header: true,
            columns: defaultColumns,
        });
        const defaultPageOptions = usePageOptions();
        const pageOptions = [defaultPageOptions.viewMode, defaultPageOptions.fullscreen];

        return {
            t,
            route,
            table,
            pageOptions,
            ...contextMenu,

            title: ref(''),
            tasks: ref([] as ITask[]),
            searchString: ref(''),
            mutatorContext: ref(null as Raw<IMutatorContext> | null),

            TaskFilterTypes: markRaw(TaskFilterTypes),
        };
    },

    computed: {
        order: Storages.Filters.computed<IOrder>(Settings.UI.Order + '.user.tasks', {
            orderBy: 'id',
            orderAscending: false,
        }),
        filters: Storages.Filters.computed(Settings.UI.Filters + '.user.tasks', { value: [], formattedValue: {} }),
        pageSettings: Storages.Settings.computed<IPageSettings>(Settings.UI.PageSettings + '.user.tasks', {
            viewMode: 'board',
            fullscreen: false,
        }),

        currentUser(): IUser {
            return store.state.user;
        },

        columns(): IColumn[] {
            let columns: IColumn[] = [
                {
                    id: -1,
                    order: 0,
                    title: this.t('user-draft-column-title'),
                    boardId: Constants.DraftId,
                    projectId: -1,
                },
                {
                    id: -1,
                    order: 0,
                    title: this.t('user-approvements-column-title'),
                    boardId: Constants.ApprovementId,
                    projectId: -1,
                },
            ];

            if (!store.state.projects?.length || !store.state.boards?.length) {
                return columns;
            }

            columns = store.state.projects.reduce((carry, project): IColumn[] => {
                const boards = store.state.boards?.filter(
                    (board) => board.type === ProjectBoardType.Tasks && board.projectId === project.id,
                );

                if (!boards?.length) {
                    return carry;
                }

                const projectTitle = tryTranslate(project.title);
                for (const board of boards) {
                    const boardTitle = tryTranslate(board.title);
                    const title = boardTitle + ', ' + projectTitle;
                    const orderStr = projectTitle + boardTitle;
                    const column: IColumn = {
                        id: -1,
                        order: 1,
                        orderStr: orderStr,
                        title: title,
                        boardId: board.id,
                        projectId: board.projectId,
                    };

                    carry.push(column);
                }

                return carry;
            }, columns);

            return sortBy(columns, (column) => column.order + (column.orderStr ?? ''));
        },

        groupedTasks(): Record<number, ITask[]> {
            return this.columns.reduce((carry: Record<number, ITask[]>, column) => {
                // The order was marked as raw for not tracking changes.
                carry[column.boardId] = orderBy(
                    this.getTasks(column),
                    toRaw(this.order).orderBy,
                    toRaw(this.order).orderAscending ? 'asc' : 'desc',
                );

                return carry;
            }, {} as Record<number, ITask[]>);
        },

        tasksFilters(): QueryTaskRequest {
            return {
                ...this.order,
                ...this.filters.formattedValue,
                whereType: [TaskType.Task, TaskType.Subtask],
                whereColumnId: this.columns?.map((column) => column.id).filter((id) => id > 0),
                whereCollaborator: [this.currentUser?.id as string],
                includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators'],
                search: this.searchString ?? undefined,
            };
        },
    },
    methods: {
        getTasks(column: IColumn): ITask[] {
            if (!this.tasks?.length) {
                return [];
            }

            if (column.boardId === Constants.DraftId) {
                return this.tasks.filter((task) => task.status === Status.Draft);
            }

            if (column.boardId === Constants.ApprovementId) {
                return this.tasks.filter((task) => task.status === Status.Approvement);
            }

            const boardId = column.boardId > 0 ? column.boardId : null;

            return this.tasks.filter((task) => task.boardId === boardId) ?? [];
        },

        async fetchData(): Promise<void> {
            this.tasks = await TaskService.queryAsync(this.tasksFilters);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.tasks);
            ObjectStorageMapper.mapTasksAsync(this.tasks);

            MutationBus.deactivate(this.mutatorContext);
            this.mutatorContext = markRaw(
                new TaskMutatorContext(this.tasks, {
                    mapUsers: true,
                    mapPreview: true,
                    // Fetches a task if the patch contains the changes that can affect of the filters.
                    fetchTask: async (patch: Partial<ITask>) => {
                        if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.tasksFilters)) {
                            return undefined;
                        }

                        const tasks = await TaskService.queryAsync({
                            ...this.tasksFilters,
                            whereId: [patch.id as number],
                            perPage: 1,
                        });

                        if (!tasks.length) {
                            return undefined;
                        }

                        return UserMapper.mapTaskAsync(tasks[0]);
                    },

                    // Excludes tasks if they are not matching the specified filters.
                    excludeTask: (task: ITask) =>
                        !TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                    // Ignores tasks creation if they are not matching the specified filters.
                    ignoreTaskCreating: (task: ITask) =>
                        TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                }),
            );
            MutationBus.activate(this.mutatorContext);
        },

        onTaskPanelBeforeOpen(event: { taskId: number; clientWidth: number }): void {
            this.$nextTick(() => {
                const taskElement = document.querySelector(`.task-card[task-id='${event.taskId}']`) as HTMLElement;
                if (taskElement) {
                    scrollIntoView(taskElement, {
                        time: 250,
                    });
                }
            });
        },
    },

    watch: {
        tasksFilters: 'fetchData',
    },

    created(): void {
        this.title = this.t('my-tasks');

        this.fetchData();

        emitter.on(EventNames.TaskPanelBeforeOpen, this.onTaskPanelBeforeOpen);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    mounted(): void {
        setPageTitle(this.t('my-tasks'));
    },

    beforeUnmount(): void {
        // Deactivate old context, to avoid memory leaks.
        MutationBus.deactivate(this.mutatorContext);

        emitter.off(EventNames.TaskPanelBeforeOpen, this.onTaskPanelBeforeOpen);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
</script>
