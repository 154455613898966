import Storages from '@/core/Storages';
import ITable, { ITableColumn } from '@/core/Values/ITable';
import { computed, h, ref } from 'vue';
import vueI18n from '@/plugins/VueI18n';
import ITask from '@/core/Models/ITask';
import VUserCard from '@/components/VUserCard.vue';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import { formatDateOnly } from '@/utils/date-utils';
import Status from '@/core/Values/Status';
import { DateTime } from 'luxon';
import VTaskStatus from '@/components/VTaskStatus.vue';
import TextSvg from '@/assets/text.svg';
import EmailSvg from '@/assets/email.svg';
import AttachmentSvg from '@/assets/attachment.svg';
import { Text } from 'vue';

function quarterColumn(quarter: number, visible = false): ITableColumn<ITask> {
    return {
        key: 'quarter' + quarter,
        orderKey: 'deadline',
        render: (task: ITask) => {
            if (!task.deadline) {
                return;
            }

            const date = DateTime.fromISO(task.deadline);

            if (!date) {
                return;
            }

            const quarterDeadline = Math.floor((new Date(task.deadline).getMonth() + 3) / 3);

            if (quarterDeadline !== quarter) {
                return;
            }

            let className = 'v-table__content--text';

            if (
                task.deadline &&
                task.status === Status.InProgress &&
                DateTime.fromISO(task.deadline, { zone: 'UTC' }) < DateTime.now()
            ) {
                className = className + ' v-table__content--red';
            }

            if (task.status === Status.Finished || task.status === Status.Completed) {
                className = className + ' v-table__content--green';
            }

            return h('span', { className }, task?.deadline ? formatDateOnly(task.deadline) : '');
        },
        title: vueI18n.global.t('quarter' + quarter),
        visible: visible,
        required: false,
        sortable: true,
        draggable: true,
        minWidth: 5,
    };
}

export const allColumns = {
    title: {
        key: 'title',
        orderKey: 'title',
        render: (task: ITask) => {
            let titleClassName = 'text text--overflow text--left';

            if (!task.title || task.status === Status.Finished || task.status === Status.Completed) {
                titleClassName = titleClassName + ' text--blurry';
            }

            if (task.status === Status.Finished || task.status === Status.Completed) {
                titleClassName = titleClassName + ' text--line-through text--italic-with-padding';
            }

            const children = [
                h(VTaskStatus, { task, showTitle: false }),
                h('span', { className: titleClassName }, task.title ? task.title : vueI18n.global.t('untitled')),
            ];

            if (task.description) {
                children.push(h(TextSvg, { class: 'svg svg--150' }));
            }

            if (typeof task.commentsCount === 'number' && task.commentsCount > 0) {
                children.push(h(EmailSvg, { class: 'svg svg--150' }));
                children.push(h(Text, task.commentsCount));
            }

            if (typeof task.attachmentsCount === 'number' && task.attachmentsCount > 0) {
                children.push(h(AttachmentSvg, { class: 'svg svg--150' }));
            }

            children.push(h('span', { style: { width: '0.5rem' } }));

            return h(
                'div',
                { className: 'v-table__content v-table__content--center v-table__content--shrink' },
                children,
            );
        },
        title: vueI18n.global.t('task'),
        visible: true,
        required: true,
        draggable: false,
        minWidth: 20,
    } as ITableColumn<ITask>,
    deadline: {
        key: 'deadline',
        orderKey: 'deadline',
        render: (task: ITask) => {
            let className = 'v-table__content--text';

            if (
                task.deadline &&
                task.status === Status.InProgress &&
                DateTime.fromISO(task.deadline, { zone: 'UTC' }) < DateTime.now()
            ) {
                className = className + ' v-table__content--red';
            }

            if (task.status === Status.Finished || task.status === Status.Completed) {
                className = className + ' v-table__content--green';
            }

            return h('span', { className }, task?.deadline ? formatDateOnly(task.deadline) : '');
        },
        title: vueI18n.global.t('deadline'),
        visible: true,
        required: false,
        sortable: true,
        draggable: true,
        minWidth: 5,
    } as ITableColumn<ITask>,
    authorId: {
        key: 'authorId',
        orderKey: 'authorId',
        render: (task: ITask) =>
            task.author
                ? h(
                      'div',
                      { className: 'v-table__content v-table__content--center v-table__content--margin' },
                      h(VUserCard, { user: task.author, tiny: true }),
                  )
                : null,
        title: vueI18n.global.t('author'),
        visible: true,
        required: false,
        sortable: true,
        draggable: true,
        className: 'v-table__content--center v-table__content--margin',
    } as ITableColumn<ITask>,
    assignee: {
        key: 'assignee',
        render: (task: ITask) => {
            const assignee = task.collaborators?.find(
                (collaborator) => collaborator.role === CollaboratorRole.Assignee,
            );

            return assignee?.user
                ? h(
                      'div',
                      { className: 'v-table__content v-table__content--center v-table__content--margin' },
                      h(VUserCard, { user: assignee.user, tiny: true }),
                  )
                : null;
        },
        title: vueI18n.global.t('assignee'),
        visible: true,
        required: false,
        sortable: false,
        draggable: true,
    } as ITableColumn<ITask>,
    status: {
        key: 'status',
        orderKey: 'status',
        render: (task: ITask) => {
            return h('div', { className: 'v-table__content v-table__content--center' }, [h(VTaskStatus, { task })]);
        },
        title: vueI18n.global.t('status'),
        visible: false,
        required: false,
        sortable: true,
        draggable: true,
    } as ITableColumn<ITask>,
    quarter1: quarterColumn(1),
    quarter2: quarterColumn(2),
    quarter3: quarterColumn(3),
    quarter4: quarterColumn(4),
};

export const defaultColumns: ITableColumn<ITask>[] = Object.values(allColumns);
export const journalColumns: ITableColumn<ITask>[] = [
    allColumns.title,
    allColumns.authorId,
    allColumns.assignee,
    quarterColumn(1, true),
    quarterColumn(2, true),
    quarterColumn(3, true),
    quarterColumn(4, true),
];

export default function useTaskTableViewer(key: string, table: ITable<ITask>) {
    const raw = Storages.Settings.get<Partial<ITable<ITask>>>(key);
    const local = ref(table);

    table.columns = table.columns
        .map((column) => {
            const index = raw?.columns?.findIndex((item) => item.key === column.key) ?? -1;

            if (index === -1) {
                return {
                    index,
                    ...column,
                };
            }

            const carry = raw?.columns?.[index];
            return {
                index,
                ...column,
                width: carry?.width ?? column.width,
                visible: carry?.visible ?? column.visible,
            };
        })
        .sort((left, right) => left.index - right.index);

    const result = computed<ITable>({
        get: () => local.value as ITable,
        set: (value: ITable<ITask>) => {
            local.value = value;
            Storages.Settings.set<Partial<ITable<ITask>>>(key, {
                columns: local.value.columns.map((column) => ({
                    key: column.key,
                    width: column.width,
                    visible: column.visible,
                })),
            } as Partial<ITable<ITask>>);
        },
    });

    return result;
}
